import React from "react";
import "./MiniCarouselItem.css";
// import { useSelector } from "react-redux";

import imagePhoto from "../../assets/images/image-photo-test5.jpeg";

const MiniCarouselItem = () => {
  return (
    <div className="mini-carousel-item">
      <img src={imagePhoto} alt="imagePhoto" />
    </div>
  );
};

export default MiniCarouselItem;