import React from "react";

import imageSocialYandex from "../../../../assets/images/icon-social-yandex.svg";
import imageSocialMail from "../../../../assets/images/icon-social-mail.svg";
import imageSocialVk from "../../../../assets/images/icon-social-vk.svg";
import imageSocialOk from "../../../../assets/images/icon-social-ok.svg";

const Socials = () => {
    return (
        <div className="modal-social-box hidden">
            <button className="btn-social-login general__login-yandex" id="registration__login-yandex">
                <img src={imageSocialYandex} alt="image-social-yandex" />
            </button>
            <button className="btn-social-login general__login-mail" id="registration__login-mail">
                <img src={imageSocialMail} alt="image-social-mail" />
            </button>
            <button className="btn-social-login general__login-vk" id="registration__login-vk">
                <img src={imageSocialVk} alt="image-social-vk" />
            </button>
            <button className="btn-social-login general__login-ok" id="registration__login-ok">
                <img src={imageSocialOk} alt="image-social-ok" />
            </button>
        </div>
    )
}

export default Socials