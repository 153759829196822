import { createContext, useContext, useState } from 'react'


const ModalCxt = createContext()

export const useModal = () => useContext(ModalCxt)


export default function Ctx({ children }) {

    const [ModalVisible, setModalVisible] = useState({
        fileExist: false,
        exitConfirm: false,
        progressBar: false,
        photoCarousel: false,
    }) 

    const open = (type) => setModalVisible({ ...ModalVisible, [type]: true })

    const close = (type) => setModalVisible({ ...ModalVisible, [type]: false })
  
    return (
        <ModalCxt.Provider value={{
                                   ModalVisible, 
                                   open, 
                                   close
                                }}>
            { children }
        </ModalCxt.Provider>
    )
}