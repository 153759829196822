import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleModalsAction } from "../../../../reducers/modal.reducer";

import imageCooperation from "../../../../assets/images/image-cooperation.jpg";
import video1280_720_test2 from "../../../../assets/images/video-1280_720_test2.webm";
import imagePhoto1 from "../../../../assets/images/image-photo-test5.jpeg";
import imageLeftArrow from "../../../../assets/images/icon-arrow-left.svg";
import imagePlay from "../../../../assets/images/icon-play.svg";

import "./Cooperation.css";

import MiniCarouselItem from "../../../MiniCarouselItem/MiniCarouselItem";

const Cooperation = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="cooperation">
      {/* Оболочка для компановки всего контента */}
      <div className="cooperation__wrapper">
        {/* Заголовок */}
        <div className="cooperation__title">
        <h2>Сотрудничество</h2>
        </div>
        {/* Блок - Видео и текст */}
        <div className="cooperation__video-text">
          <div className="cooperation__video-box hidden">
            <video id="cooperation__video" width="752" height="423" controls>
                <source src={video1280_720_test2} type="video/webm" />
                Ваш браузер не поддерживает встроенные видео.
            </video>
            {/* Слой с кнопкой Плей */}
            <div className="overlay" id="overlay" >
                <img src={imagePlay} alt="Play" />
            </div>
          </div>
          <div className="cooperation__text-box">
            <p>
              Присоединяйтесь к нашей команде фотографов!
            </p><br></br>
            <p>
              Привлекайте посетителей с помощью фотосъёмки!
              Организуем съёмки для ваших гостей.
              Увеличиваем посещаемость и вовлечённость аудитории.
              Работаем профессионально, гибко адаптируемся под ваши задачи.
              Свяжитесь с нами, чтобы обсудить детали и сделать ваше пространство популярным среди гостей!
            </p>
            <div className="cooperation__btns-box">
              {/* <button className="btn-dark" 
                onClick={() => dispatch(toggleModalsAction("cooperation_modal"))}>
                <p>Оставить заявку</p>
              </button> */}
              <button className="btn-dark"
                onClick={() => dispatch(toggleModalsAction("cooperation_modal"))}>
                <p>Есть аккаунт</p>
              </button>
              <button className="btn-dark"
                onClick={() => navigate('/signupphotographer')}
              >
                <p>Зарегистрироваться</p>
              </button>
            </div>
          </div>
        </div>
        {/* Карусель мини-фото */}
        <div className="mini-carousel hidden">
          <div className="mini-carousel-photos-box">
            <div className="mini-carousel-wrapper-photos">
              <MiniCarouselItem></MiniCarouselItem>
              <MiniCarouselItem></MiniCarouselItem>
              <MiniCarouselItem></MiniCarouselItem>
            </div>
            {/* Кнопка влево */}
            <div className="mini-carousel__left-btn-box opacity30">
              <img src={imageLeftArrow} alt="imageLeftArrow" />
            </div>
            {/* Кнопка вправо */}
            <div className="mini-carousel__right-btn-box opacity30">
              <img src={imageLeftArrow} alt="imageLeftArrow" />
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default Cooperation;