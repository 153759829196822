import React from "react";
import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../actions/user";
import Home from "../Pages/Home/Home";
import Greetings from "../Pages/Home/Greetings/Greetings";
import Cooperation from "../Pages/Home/Cooperation/Cooperation";
import About from "../Pages/Home/About/About";
import Account from "../Pages/Home/Account/Account";
import Purchase from "../Pages/Home/Purchase/Purchase";
import Gallery from "../Pages/Home/Gallery/Gallery";
import Cart from "../Pages/Home/Cart/Cart";
import FindSelf from "../Pages/Home/FindSelf/FindSelf";
import Vacancies from "../Pages/Home/Vacancies/Vacancies";
import Contacts from "../Pages/Home/Сontacts/Contacts";
import Wait from "../Pages/Alerts/Wait";
import Connection from "../Pages/Alerts/Connection";
import Techworks from "../Pages/Alerts/TechWorks";
import Undefined from "../Pages/Alerts/Undefined";
import OrganizationRegAccountType from "../OrganizationRegAccountType/OrganizationRegAccountType";
import PhotographerReg from "../OrganizationRegAccountType/PhotographerReg/PhotographerReg";
import ConfirmEmailReg from "../OrganizationRegAccountType/ConfirmEmailReg/ConfirmEmailReg";
import CompanyRegStepOne from "../OrganizationRegAccountType/CompanyRegStepOne/CompanyRegStepOne";
import CompanyRegStepTwo from "../OrganizationRegAccountType/CompanyRegStepTwo/CompanyRegStepTwo";

import "./normalize.css";
import "./fonts.css";
import "./App.css";
import "./_styles-live-chat.scss";

const App = () => {

  const dispatch = useDispatch();
  const isAuth = useSelector(state => state.user.isAuth);

  useEffect(() => {
    dispatch(auth());
  }, [isAuth]);


  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<Greetings />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="cart" element={<Cart />} />
          <Route path="account" element={<Account />} />
          <Route path="findself" element={<FindSelf />} />
          <Route path="about" element={<About />} />
          <Route path="vacancies" element={<Vacancies />} />
          {/* <Route path="cooperation" element={<OrganizationRegAccountType />} /> */}
          <Route path="signupphotographer" element={<PhotographerReg />} />
          <Route path="confirmphreg" element={<ConfirmEmailReg />} />
          {/* <Route path="cooperation" element={<CompanyRegStepOne />} /> */}
          {/* <Route path="cooperation" element={<CompanyRegStepTwo />} /> */}
          <Route path="cooperation" element={<Cooperation />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="puschase" element={<Purchase />} />
        </Route>
        <Route path="/alert-wait" element={<Wait />} />
        <Route path="/alert-connection" element={<Connection />} />
        <Route path="/alert-techworks" element={<Techworks />} /> 
        <Route path="*" element={<Undefined />} />
      </Routes> 
    </div>
  );
}

export default App;
