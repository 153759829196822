import { myConfig as api } from './config/config'

// почему используем нижний пробел в названии функции?
export const _req = async (url, method = 'get', Data = null) => {

    try {
        let res = null

        if(method !== 'get') {
            const fd = new FormData()
    
            for(let key in Data) {
                fd.append(key, Data[key])
            }
    
            res = await fetch(`${api.serverURI}${url}`, {
                method,
                body: fd
            })
    
        } else {
            res = await fetch(`${api.serverURI}${url}`)
        }
        
        const resData = await res.json()

        console.log(resData)

        return resData

    } catch(err) {
        console.log(err)
    }
}