import axios from "axios";
import { myConfig as api } from '../config/config'
import { addToCartAction, removeFromCartAction } from "../reducers/user.reducer";

const token = `Bearer ${localStorage.getItem('token')}`

export const addToCart = (userUid, imageUid) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${api.serverURI}/api/cart/add`, {token, imageUid, userUid})
            
            switch(response.data.status) {
                case 'ok': return dispatch(addToCartAction(imageUid))
                case 'err': return console.log('SERVER is not responding!')
                default: return 'Incorrect DATA'
            }
        } catch(e) {
            console.log(`ERROR - addToCart`)
        }
    }
}

export const removeFromCart = (userUid, imageUid) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${api.serverURI}/api/cart/remove`, {token, imageUid, userUid})
            
            switch(response.data.status) {
                case 'ok': return dispatch(removeFromCartAction(imageUid))
                case 'err': return console.log('SERVER is not responding!')
                default: return 'Incorrect DATA'
            }
        } catch(e) {
            console.log(`ERROR - removeFromCart`)
        }
    }
} 