import React from "react";
import { useDispatch } from "react-redux";
import { toggleModalsAction } from "../../../../reducers/modal.reducer";

import imageCooperation from "../../../../assets/images/image-cooperation.jpg";
import videoPhotographer from "../../../../assets/images/video-photographer_3840x2160.webm";
import imagePhoto1 from "../../../../assets/images/image-photo-test5.jpeg";
import imageLeftArrow from "../../../../assets/images/icon-arrow-left.svg";
import imagePlay from "../../../../assets/images/icon-play.svg";

import MiniCarouselItem from "../../../MiniCarouselItem/MiniCarouselItem";

import "./Vacancies.css";
import imagePhotographerWithFamily from "../../../../assets/images/image-photographer-with-family.jpg";

const Vacancies = () => {

  const dispatch = useDispatch();

  return (
    <div className="vacancies">
      {/* Оболочка для компановки всего контента */}
      <div className="vacancies__wrapper">
        {/* Заголовок */}
        <div className="vacancies__title">
        <h2>Вакансии</h2>
        </div>
        {/* Блок - Видео и текст */}
        <div className="vacancies__video-text">
          <div className="vacancies__video-box">
            <video id="vacancies__video" width="752" height="423" controls>
                <source src={videoPhotographer} type="video/webm" />
                Ваш браузер не поддерживает встроенные видео.
            </video>
            <div className="overlay" id="overlay" >
                <img src={imagePlay} alt="Play" />
            </div>
          </div>
          <div className="vacancies__text-box">
            <p>
              Присоединяйтесь к нашей команде фотографов!
            </p><br></br>
            <p>
              Заработок от 70 000 рублей в месяц. Заполните анкету и узнайте больше! available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to in the middle of text.
            </p>
            <div className="vacancies__btn-box">
              <button className="btn-dark" 
                onClick={() => dispatch(toggleModalsAction("cooperation_modal"))}>
                <p>Заполнить анкету</p>
              </button>
            </div>
          </div>
        </div>
        {/* Карусель мини-фото */}
        <div className="mini-carousel">
          <div className="mini-carousel-photos-box">
            <div className="mini-carousel-wrapper-photos">
              <MiniCarouselItem></MiniCarouselItem>
              <MiniCarouselItem></MiniCarouselItem>
              <MiniCarouselItem></MiniCarouselItem>
              <MiniCarouselItem></MiniCarouselItem>
              <MiniCarouselItem></MiniCarouselItem>
              <MiniCarouselItem></MiniCarouselItem>
              <MiniCarouselItem></MiniCarouselItem>
              <MiniCarouselItem></MiniCarouselItem>
              <MiniCarouselItem></MiniCarouselItem>
              <MiniCarouselItem></MiniCarouselItem>
            </div>
            {/* Кнопка влево */}
            <div className="mini-carousel__left-btn-box opacity30">
              <img src={imageLeftArrow} alt="imageLeftArrow" />
            </div>
            {/* Кнопка вправо */}
            <div className="mini-carousel__right-btn-box opacity30">
              <img src={imageLeftArrow} alt="imageLeftArrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vacancies;