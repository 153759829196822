import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./ConfirmExit.css";
import { closeAllModalsAction } from '../../../reducers/modal.reducer'
import { logout } from "../../../actions/user";

const ConfirmExit = () => {

  const isConfirmExitActive = useSelector(state => state.modal.types.confirm);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className={isConfirmExitActive ? "confirm-exit" : "confirm-exit hidden"}>
      <p>Вы уверены что хотите выйти?</p>
      <div className="confirm-exit__buttons-box">
        <button 
          type="button" 
          className="btn-dark" 
          id="btn-confirm-exit__logout"
          onClick={() => {
            navigate('/')
            dispatch(closeAllModalsAction())
            dispatch(logout())
            
          }}
        >
          <p>Выйти</p>
        </button>
        <button 
          type="button" 
          className="btn-dark" 
          id="btn-confirm-exit__stay"
          onClick={() => dispatch(closeAllModalsAction())}
        >
          <p>Остаться</p>
        </button>
      </div>
    </div>
  );
};

export default ConfirmExit;