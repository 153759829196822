import { closeAllModalsAction, toggleModalsAction, setErrorAction } from "../reducers/modal.reducer"

export const closeAllModals = () => {
  return async dispatch => {
    try {
      dispatch(closeAllModalsAction())
    } catch (e) {
      console.log('closeAllModals error')
      console.log(e)
    }
  }
}

export const toggleModals = (modal) => {
  return async dispatch => {
    try {
      dispatch(toggleModalsAction(modal))
    } catch (e) {
      console.log('toggleModals error')
      console.log(e)
    }
  }
}

export const setError = (error) => {
  return async dispatch => {
    try {
      dispatch(setErrorAction(error))
    } catch (e) {
      console.log('setError error')
      console.log(e)
    }
  }
}