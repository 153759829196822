import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { _req } from '../../utils'
import { myConfig as api } from "../../config/config";
import axios from 'axios'
import { toggleModals, setError } from '../../actions/modal';
import { setUserAction } from "../../reducers/user.reducer";
import "./Footer.css";
// import imageFamilyReal1 from "../../assets/images/image-family-real-1.jpg";

const Footer = () => {

  const location = useLocation();
  const isCartLocation = location.pathname === '/cart';
  const user = useSelector(state => state.user);
  const length = user.cart ? user.cart.length : 0
  const [isLoading, setIsLoading] = useState(false) //! установить спинер пока идет создание заказа на беке
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createOrder = async () => {
    try {
      const response = await axios.post(`${api.serverURI}/api/order/create`, {
        amount: length * 200, 
        images: user.cart,
        uid: user.uid
      })

      switch(response.data.status) {
        case 'ok': return pay(response.data.data.orderUid, response.data.data.amount, user)
        case 'err': return console.log('Нет ответа от сервера!') //! здесь модалка с ошибкой
        default: return 'Incorrect DATA'
      }
      
    } catch (error) {

      //* покачто если сервер будет выключен просто ничего не будет происходить
      //* но в будущем НАДО будет обработать этот случай
      //! здесь модалка с ошибкой
      console.log(error)
      console.log('Сервер временно не доступен!')

    }
  };

  const pay = async (orderUid, amount, user) => {

    const widget = new window.cp.CloudPayments();
    widget.pay('auth', // или 'charge'
        { //options
            publicId: 'pk_420fe21233eb14bfaeafd124380b0', //id из личного кабинета
            description: 'Оплата товаров в imhere.studio', //назначение
            amount, //сумма
            currency: 'RUB', //валюта
            accountId: user.uid, //идентификатор плательщика (необязательно)
            invoiceId: orderUid, //номер заказа  (необязательно)
            email: user.email, //email плательщика (необязательно)
            skin: "mini", //дизайн виджета (необязательно)
            autoClose: 3, //время в секундах до авто-закрытия виджета (необязательный)
            data: {
                myProp: 'myProp value (show this field in callback)'
            },
            configuration: {
                common: {
                    successRedirectUrl: "https://imhere.studio/gallery", // адреса для перенаправления 
                    failRedirectUrl: "https://imhere.studio"        // при оплате по T-Pay
                }
            },
            payer: { 
                firstName: user.first_name,
                lastName: user.last_name,
                middleName: user.surname,
                birth: '',
                address: '',
                street: '',
                city: '',
                country: 'RU',
                phone: '',
                postcode: ''
            }
        },
        {
            onSuccess: function (options) { // success
                
                //действие при успешной оплате
                console.log('success', options)

                //! Здесь надо разобраться что это вообще такое

            },
            onFail: function (reason, options) { // fail
                
                //действие при неуспешной оплате
                console.log('fail', reason, options)

                //! show modal FAIL with reason

            },
            onComplete: async function (paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                //например вызов вашей аналитики
                try {
                    console.log('complete',paymentResult, options)

                    if(paymentResult.success) {
    
                        //! отправляем запрос на сервер и результат диспатчим новые client_images
                        //! также редиректим на галерею
    
                        const response = await _req('/api/order/close', 'POST', {orderUid, userUid: user.uid})

                        switch(response.status) {
                            case 'ok': {
                                console.log('success close order')
                                dispatch(setUserAction(response.data))
                                return navigate('/gallery')
                            }
                            case 'err': return console.log('fail close order')
                            default: return console.log('Incorrect DATA')
                        }
    
                    } else {
                        alert('fail pay')
                    }
                } catch(e) {
                    console.log(e)
                    //! отсюда нужно вызывать модалку с ошибкой если что 
                }
            }
        }
    )
}

  return (
    <>
      <div className={!isCartLocation || !length ? "footer hidden" : "footer"}>
        <div className="footer__left">
          <div className="footer__text-add-box">
            <p className="footer__text-add">Добавлено: <span className="footer__volume-photo" id="footer__volume-photo">{length}</span> фото</p>
            <p className="footer__text-add">Сумма: <span className="footer__cost-photos" id="footer__cost-photos">{length * 200}</span>р.</p>
          </div>
          <div className="footer__text-select-box hidden">
            <p className="footer__text-select">Выбрано: <span className="footer__volume-photo" id="footer__volume-photo">4</span> фото</p>
          </div>
        </div>
        <div className="footer__right">
          <button className="btn-dark hidden">
            <p><span className="footer__right-btn-part-text">Добавить</span> в корзину</p>
          </button>
          <button className="btn-dark hidden">
            <p>Скачать <span className="footer__right-btn-part-text">фотографии</span></p>
          </button>
          <button 
            className={`btn-dark ${isLoading ? 'btn-disabled' : ''}`}
            onClick={createOrder}
            disabled={isLoading}
          >
            <p>Оплатить</p>
          </button>
        </div>
      </div>
    </>
  ); 
}

export default Footer;