import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Components/App/App';
import { BrowserRouter as Router} from "react-router-dom";
import { store } from './reducers';
import { Provider } from 'react-redux'; 
import ModalProvider from './context/ModalContext';


// document.addEventListener('DOMContentLoaded', () => {
//   const widget = document.querySelector('dg-social-widget');
//   console.log(widget)
//   const shadowRoot = widget.shadowRoot;
//   console.log(shadowRoot)

//   if (shadowRoot) {
//     const fixedDiv = shadowRoot.querySelector('.fixed');
//     console.log(fixedDiv)
//     if (fixedDiv) {
//       console.log(fixedDiv)
//       fixedDiv.style.bottom = '100cnpx';
//     }
//   }
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ModalProvider>
      <Provider store={store}>
        <Router future={{v7_startTransition: true, v7_relativeSplatPath: true,}}>
          <App />
        </Router>
      </Provider>
    </ModalProvider>
  </React.StrictMode>
);