import React from "react";

import imageAbout from "../../../../assets/images/image-photographers.jpg";

import "./About.css";

const About = () => {

  return (
    <div className="about">
      <div className="about__title-box">
        <h2 className="about__title">О нас</h2>
      </div>
      <div className="about__image-box">
        <img src={imageAbout} alt="image-about" />
      </div>
      <div className="about__text-box">
        <p>
          Наша команда фотографов - истинные мастера в захватывании моментов. Страсть к искусству, креативный подход и профессионализм помогут оживить ваше событие и сохранить ваши незабываемые моменты.
        </p>
      </div>
    </div>
  );
};

export default About;