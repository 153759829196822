import React from "react";
import { useSelector } from "react-redux";
import "./Success.css";

const Success = () => {

  const isSuccessActive = useSelector(state => state.modal.types.success);

  return (
    <div className={isSuccessActive ? "success-msg" : "success-msg hidden"}>
      <p>Ваша заявка успешно отправлена.</p>
    </div>
  );
};

export default Success;