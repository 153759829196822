import React from "react";

const AgreedBox = () => {

    return (
        <div className="modal-agreed-box">
            <p>
            Авторизуясь вы соглашаетесь<br />
            с <button><p>условиями конфиденциальности.</p></button>
            </p>
        </div>
    )
}

export default AgreedBox