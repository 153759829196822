import React from "react";
import "./Greetings.css";
import imageFamilyFaces from "../../../../assets/images/image-family-faces.jpg";

const Greetings = () => {
  return (
    <div className="greetings">
      <div className="greetings__title">
       <h1>Маркетплейс твоих фотографий&nbsp;!</h1>
      </div>
      <div className="greetings__image-box">
        <img src={imageFamilyFaces} alt="image-family" />
      </div>
      <div className="greetings__text-box">
       <p>Добро пожаловать!<br></br> Найдите свои фотографии, сделанные профессиональными фотографами в любимых местах отдыха. Просто отсканируйте свое лицо, и мы найдем ваши снимки с незабываемыми моментами!</p>
      </div>
    </div>
  );
}

export default Greetings;