import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "./GalleryProfileEdit.css";

import imageProfileCircle from "../../../assets/images/image-profile-circle.png";
import iconSuareSmile from "../../../assets/images/icon-square-smile.svg";
import iconPencil from "../../../assets/images/icon-pencil.svg";
import iconGarbage from "../../../assets/images/icon-garbage.svg";

const GalleryProfileEdit = () => {
    const isGalleryProfileEditActive = useSelector(state => state.modal.types.galleryprofile);
    // const dispatch = useDispatch();

    return (
        <div className={isGalleryProfileEditActive ? "gallery-profile-edit" : "gallery-profile-edit hidden"}>
            <div className="gallery-profile-edit__top">
                {/* Блок - Аватар */}
                <div className="gallery-profile-edit__avatar-box">
                    <img src={imageProfileCircle} alt="image-profile" />
                    <p>Андрей</p>
                </div>
                {/* Юлок Имя(сверху), кнопки, поле для ввода, кнопка*/}
                <div className="gallery-profile-edit__top-right">
                    {/* Имя сверху */}
                    <div className="gallery-profile-edit__name-box">
                        <p>Андрей</p>
                    </div>
                    {/* Кнопки */}
                    <div className="gallery-profile-edit__buttons-box">
                        {/* Кнопка - сменить фото */}
                        <div className="gallery-profile-edit__button-item opacity70">
                            <img src={iconSuareSmile} alt="icon-smile" />
                            <p>Сменить фото</p>
                        </div>
                        {/* Кнопка - изменить имя */}
                        <div className="gallery-profile-edit__button-item opacity70">
                            <img src={iconPencil} alt="icon-pencil" />
                            <p>Изменить имя</p>
                        </div>
                        {/* Кнопка - удалить галерею */}
                        <div className="gallery-profile-edit__button-item opacity70">
                            <img src={iconGarbage} alt="icon-garbage" />
                            <p>Удалить<span className="gallery-profile-edit__button-text-garbage"> галерею</span></p>
                        </div>
                        
                    </div>
                    <div className="gallery-profile-edit__input-box hidden">
                        <div className="gallery-profile-edit__text-new-name">
                            <p>Новое имя:</p>
                        </div>
                        <div className="modal-input-box gallery-profile-edit__input-and-button">
                            <input className="modal-input" type="text" />
                            <button className="btn-modal btn-dark-to-light"><p>Сохранить</p></button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>    
    )
}

export default GalleryProfileEdit