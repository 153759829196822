import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { myConfig as api } from "../../../config/config";
import { toggleModals } from "../../../actions/modal";
import { setCooperationUserAction } from "../../../reducers/cooperation.reducer";

// import video1280_720_test2 from "../../../../assets/images/video-1280_720_test2.webm";
import imagePasswordShow from "../../../../src/assets/images/icon-password-show.svg";
import imagePasswordDontShow from "../../../../src/assets/images/icon-password-dont-show.svg";

import "./PhotographerReg.css";
import ApiError from "../../Modals/Errors/ApiError/ApiError";

const PhotographerReg = () => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [incorrectEmail, setIncorrectEmail] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [surname, setSurname] = useState('');
  const [password, setPassword] = useState('');
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [incorrectPasswordRepeat, setIncorrectPasswordRepeat] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [tin, setTin] = useState('');
  const [incorrectTin, setIncorrectTin] = useState(false);
  const [errorTin, setErrorTin] = useState('Некорректный ИНН');
  const [self_employed, setSelfEmployed] = useState(false);
  const [privacy_policy, setPrivacyPolicy] = useState(false);
  const [serverError, setServerError] = useState('');

  function validateEmail(email) {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (re.test(email)) {
      setIncorrectEmail(true)
    } else {
      console.log("Email is not valid");
      setIncorrectEmail(false)
    }
  }

  function validatePassword(password) {
    const re = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[!@#])[A-Za-z\d!@#]{8,}$/;

    if (re.test(password)) {
      setIncorrectPassword(true);
    } else {
      setIncorrectPassword(false);
    }
  }

  function validatePasswordRepeat(passwordRepeat) {
    if (password === passwordRepeat) {
      setIncorrectPasswordRepeat(true)
    } else {
      setIncorrectPasswordRepeat(false)
    }
  }

  function validateTin(tin) {
    const re = /^[0-9]{12}$/;
    if (re.test(tin)) {
      setIncorrectTin(true)
    } else {
      setIncorrectTin(false)
    }
  }

  async function ls_update() {
    localStorage.setItem('details', JSON.stringify({
      email: email,
      firstname: firstName,
      lastname: lastName,
      surname: surname,
      tin: tin,
      self_employed: self_employed,
      privacy_policy: privacy_policy
    }))
  }
  
  return (
    // Компонент - Регистрация Фотографа - Шаг 1 из 2
    <div className="photographer-reg">
      {
        serverError
        ?
        <ApiError message={serverError} setServerError={setServerError}/>
        :
      <>
      <div className="photographer-reg__wrapper">
        <div className="title-description">
          <h2>Регистрация фотографа</h2>
          <p>Шаг 1 из 2: Ваши данные</p>
        </div>

        {/* Форма с полями и кнопками */}
        <form className="photographer-reg__form" onSubmit={(e) => {
          e.preventDefault();

          if ([incorrectEmail, incorrectPassword, incorrectPasswordRepeat, incorrectTin, self_employed, privacy_policy].includes(false)) {
            console.log("error");
            
          } else {
            console.log("success");
            const formData = new FormData();
            formData.append("type", "photographer");
            formData.append("email", email);
            formData.append("first_name", firstName);
            formData.append("last_name", lastName);
            formData.append("surname", surname);
            formData.append("password", password);
            formData.append("tin", tin);
            formData.append("self_employed", self_employed);
            formData.append("privacy_policy", privacy_policy);
            
            fetch(`${api.serverURI}/api/user/cooperation/create`,{
              method: "POST",
              body: formData
            })
            .then(response => response.json())
            .then(data => {
              if (data.status === "ok" && !data.errorMessage) {
                // ls_update();
                dispatch(setCooperationUserAction(data.user))
                navigate("/confirmphreg");
              } else if (data.status === "ok" && data.errorMessage) {
                setErrorTin(data.errorMessage)
                setIncorrectTin(false)
              } else if (data.status === "err") {
                console.log(data.errorMessage)
                setServerError(data.errorMessage)
                dispatch(toggleModals('api_error'))
              }
            })
            .catch(error => {
              console.error(error)
              setServerError('Сервер временно не доступен')
              dispatch(toggleModals('api_error'))
            });
              
            }
          
        }}>

          {/* Box input - Email */}
          <div className="input__box">
            <div className="modal-input-box">
              <label htmlFor="reg__input-email" className="modal-label">E-mail*</label><br />
              {/* Для подсветки некорректного ввода, добавить класс modal-input-wrong */}
              <input
                onChange={(e) => {
                  validateEmail(e.target.value);
                  setEmail(e.target.value); 
                }}
                type="text" 
                spellCheck="false" 
                className="modal-input"
                name="e-mail"
                id="reg__input-email"
                />
              <br />
            </div>
            {/* Информирование - не верные данные в поле ввода */}
            <div className={incorrectEmail ? "wrong-data-box hidden" : "wrong-data-box"}>
              <p>Некорректный email</p>
            </div>
            {/* Текст - подсказка, под полем ввода */}
            <div className="hint-box">
              <p>Убедитесь, что email корректен — на него будут отправляться уведомления и подтверждения.</p>
            </div>
          </div>
          
          {/* Box input - Ф И О */}
          <div className="input-fio__wrapper">
            {/* Box input - Фамилия */}
            <div className="input__box-three">
              <div className="modal-input-box">
                <label htmlFor="reg__input-family" className="modal-label">Фамилия</label><br />
                {/* Для подсветки некорректного ввода, добавить класс modal-input-wrong */}
                <input
                  onChange={(e) => setLastName(e.target.value)}
                  type="text" 
                  spellCheck="false" 
                  className="modal-input"
                  name="fio"
                  id="reg__input-family"
                  />
                <br />
              </div>
            </div>
            {/* Box input - Имя */}
            <div className="input__box-three">
              <div className="modal-input-box">
                <label htmlFor="reg__input-name" className="modal-label">Имя</label><br />
                {/* Для подсветки некорректного ввода, добавить класс modal-input-wrong */}
                <input 
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text" 
                  spellCheck="false" 
                  className="modal-input"
                  name="fio"
                  id="reg__input-name"
                  />
                <br />
              </div>
            </div>
            {/* Box input - Отчество */}
            <div className="input__box-three">
              <div className="modal-input-box">
                <label htmlFor="reg__input-middle-name" className="modal-label">Отчество</label><br />
                {/* Для подсветки некорректного ввода, добавить класс modal-input-wrong */}
                <input 
                  onChange={(e) => setSurname(e.target.value)}
                  type="text" 
                  spellCheck="false" 
                  className="modal-input"
                  name="fio"
                  id="reg__input-middle-name"
                  />
                <br />
              </div>
            </div>
          </div>
          
          {/* Box input - Придумайте пароль */}
          <div className="input__box">
            <div className="modal-input-box password-box">
              <label htmlFor="reg__input-create-password" className="modal-label">
                Придумайте пароль*
              </label><br />
              {/* Для подсветки некорректного ввода, добавить класс modal-input-wrong */}
              <input
                onChange={(e) => {
                  validatePassword(e.target.value);
                  setPassword(e.target.value);
                }} 
                type="password" 
                spellCheck="false" 
                className="modal-input"
                name="fio"
                id="reg__input-create-password"
                />
              {/* Две кнопки показать/скрыть пароль */}
              <button class="show-dont-show-password opacity30"
                type="button"
                >
                  <img src={imagePasswordShow} alt="imagePasswordShow" />
              </button>
              <button class="show-dont-show-password opacity30 hidden"
                type="button"
                >
                  <img src={imagePasswordDontShow} alt="imagePasswordShow" />
              </button>
              <br />
            </div>
            {/* Информирование - не верные данные в поле ввода */}
            <div className={incorrectPassword ? "wrong-data-box hidden" : "wrong-data-box"}>
              <p>Пароль не соответствует требованиям</p>
            </div>
            {/* Текст - подсказка, под полем ввода */}
            <div className="hint-box">
              <p>Минимум 8 символов Буквы (верхний и нижний регистры) Цифры или специальные символы (например, !, @, #)*</p>
            </div>
          </div>

          {/* Box input - Повторите пароль */}
          <div className="input__box">
            <div className="modal-input-box password-box">
              <label htmlFor="reg__input-confirm-password" className="modal-label">
                Повторите пароль*
                </label><br />
              {/* Для подсветки некорректного ввода, добавить класс modal-input-wrong */}
              <input 
                onChange={(e) => {
                  validatePasswordRepeat(e.target.value);
                  setPasswordRepeat(e.target.value);
                }}
                type="password" 
                spellCheck="false" 
                className="modal-input"
                name="fio"
                id="reg__input-confirm-password"
                />
                {/* Две кнопки показать/скрыть пароль */}
                <button class="show-dont-show-password opacity30 hidden"
                  type="button"
                  >
                    <img src={imagePasswordShow} alt="imagePasswordShow" />
                </button>
                <button class="show-dont-show-password opacity30"
                  type="button"
                  >
                    <img src={imagePasswordDontShow} alt="imagePasswordShow" />
                </button>
              <br />
            </div>
            {/* Информирование - не верные данные в поле ввода */}
            <div className={incorrectPasswordRepeat ? "wrong-data-box hidden" : "wrong-data-box"}>
              <p>Пароли не совпадают</p>
            </div>
          </div>

          {/* Box input - ИНН */}
          <div className="input__box">
            <div className="modal-input-box">
              <label htmlFor="reg__input-inn" className="modal-label">ИНН*</label><br />
              {/* Для подсветки некорректного ввода, добавить класс modal-input-wrong */}
              <input 
                onChange={(e) => {
                  validateTin(e.target.value);
                  setTin(e.target.value);
                }}
                type="text" 
                spellCheck="false" 
                className="modal-input"
                name="e-mail"
                id="reg__input-inn"
                />
              <br />
            </div>
            {/* Информирование - не верные данные в поле ввода */}
            <div className={incorrectTin ? "wrong-data-box hidden" : "wrong-data-box"}>
              <p>{errorTin}</p>
            </div>
          </div>
          
          <div className="text-box-bold">
            <p>Все поля помеченные * обязательно должны быть заполнены.</p>
          </div>

          {/* Блок - чек-бокс и текст - являюсь самозанятым */}
          <div className="element-box">
            <div className="checkbox-wrapper">
              {/* Чекбокс - Квадратный */}
              <div className="projects__form-archive-box">
                  <div className="image__checkbox-square-select-photo-box">
                      <label className="image__checkbox-square-select-photo custom-checkbox-square">
                          <input
                            onChange={() => setSelfEmployed(!self_employed)} 
                            type="checkbox" 
                            name="customCheckbox" 
                            className="custom-checkbox-square-input" 
                          />
                          <span className="custom-checkbox-square-icon"></span>
                      </label>
                  </div>
              </div>
            </div>
            <div className="text-wrapper">
              <p>Я подтверждаю, что являюсь<span className="text-color"> самозанятым</span> и зарегистрирован в соответствии с законом о налоге на профессиональный доход.</p>
              <div className={self_employed ? "wrong-data-box hidden" : "wrong-data-box"}>
                <p>Подтвердите регистрацию самозанятости</p>
              </div>
            </div>
            
          </div>

          {/* Блок - чек-бокс и текст - подтверждаю что ознакомлен*/}
          <div className="element-box">
            <div className="checkbox-wrapper">
              {/* Чекбокс - Квадратный */}
              <div className="projects__form-archive-box">
                  <div className="image__checkbox-square-select-photo-box">
                      <label className="image__checkbox-square-select-photo custom-checkbox-square">
                          <input
                            onChange={() => setPrivacyPolicy(!privacy_policy)} 
                            type="checkbox" 
                            name="customCheckbox" 
                            className="custom-checkbox-square-input" 
                          />
                          <span className="custom-checkbox-square-icon"></span>
                      </label>
                  </div>
              </div>
            </div>
            <div className="text-wrapper">
              <p>Подтверждаю, что ознакомлен с&nbsp;
                <a href="#" className="link">Пользовательским соглашением</a>,&nbsp;
                <a href="#" className="link">Политикой конфиденциальности</a>&nbsp;
                и даю согласие на обработку данных клиентов.
              </p>
              <div className={privacy_policy ? "wrong-data-box hidden" : "wrong-data-box"}>
                <p>Подтвердите пользовательское соглашение</p>
              </div>
            </div>
          </div>

          <hr className="hr-gray"/>

          <div className="bts-box">
            <button type="button" className="btn-dark" onClick={() => { navigate(-1) }}><p>Назад</p></button>
            <button type="submit" className="btn-dark"><p>Продолжить</p></button>
          </div>
          
        </form>

      </div>
      </>
      }
    </div>
  );
}

export default PhotographerReg;
