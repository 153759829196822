import React from "react";
import "./Contacts.css";
import imageFaceBook from "../../../../assets/images/icon-facebook.svg";
import imageVk from "../../../../assets/images/icon-vk.svg";
import imageInstagram from "../../../../assets/images/icon-instagram.svg";
import imageTelegram from "../../../../assets/images/icon-telegram.svg";
import imageWhatsApp from "../../../../assets/images/icon-whatsapp.svg";
import imagePhoneCall from "../../../../assets/images/icon-phone-call.svg";
import imageEmail from "../../../../assets/images/icon-mail-in-circle.svg";

const Contacts = () => {
  return (
    <div className="contacts">
      <div className="contacts__title">
       <h2>Контакты</h2>
      </div>
      <div className="contacts__social-box">
        <button className="contacts__social-item link-disabled">
          <img src={imageFaceBook} alt="icon-facebook" />
        </button>
        <button className="contacts__social-item link-disabled">
          <img src={imageVk} alt="icon-vk" />
        </button>
        <button className="contacts__social-item link-disabled">
          <img src={imageInstagram} alt="icon-instagram" />
        </button>
        
      </div>
      <div className="contacts__tech-support-title">
        <h3>Тех. поддержка</h3>
      </div>
      <div className="contacts__tech-support-box">
        <div className="contacts__tech-support-item">
          <button className="contacts__tech-support-item"
          onClick={() => window.location.href = 'mailto:support@imhere.studio'}>
            <img src={imageEmail} alt="icon-email" />
          </button>
        </div>
        <div className="contacts__tech-support-item link-disabled">
          <button className="contacts__tech-support-item">
            <img src={imageTelegram} alt="icon-telegram" />
          </button>
        </div>
        <div className="contacts__tech-support-item link-disabled">
          <button className="contacts__tech-support-item">
            <img src={imageWhatsApp} alt="icon-whatsapp" />
          </button>
        </div>
      </div>
      <div className="contacts__phone">
        <button className="contacts__phone-content" id="btn__contacts-phone">
          <div className="contacts__phone-image-box">
            <img src={imagePhoneCall} alt="icon-phone" />
          </div>
          <div className="contacts__phone-number-box">
           <p>89998887766</p>
          </div>
        </button>
      </div>
      <div className="contacts__text-box">
        <p>
          Привлекайте посетителей с помощью фотосъёмки! Организуем съёмки для ваших гостей. Увеличиваем посещаемость и вовлечённость аудитории. Работаем профессионально, гибко адаптируемся под ваши задачи. Свяжитесь с нами, чтобы обсудить детали и сделать ваше пространство популярным среди гостей!
        </p>
      </div>
    </div>
  );
};

export default Contacts;