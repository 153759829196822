const SET_USER = 'SET_USER';
const LOGOUT = 'LOGOUT';
const SET_IMAGES = 'SET_IMAGES';
const TOGGLE = 'TOGGLE'
const ADD_TO_CART = 'ADD_TO_CART'
const REMOVE_FROM_CART = 'REMOVE_FROM_CART'

const defaultState = {
    isAuth: false,
    cart: []
}

export default function userReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_USER: 
            return {
                ...state,
                ...action.payload,
                isAuth: true
            }
        case LOGOUT:
            return {
                ...defaultState
            }
        case SET_IMAGES:
            return {
                ...state,
                client_images: [...action.payload]
            }
        case TOGGLE: {
            const {imageUid: uid, typeToggle: type} = action.payload

            if (state[type].includes(uid)) {
                return {...state, [type]: state[type].filter(image => image !== uid)}
            } else {
                return {...state, [type]: [...state[type], uid]}
            }
        }
        case ADD_TO_CART:
            return {
                ...state,
                cart: [...state.cart, action.payload]
            }
        case REMOVE_FROM_CART:
            return {
                ...state,
                cart: state.cart.filter(item => item !== action.payload)
            }
        default:
            return state
    }
}

export const setUserAction = (user) => ({type: SET_USER, payload: user})
export const setImages = (images = []) => ({type: SET_IMAGES, payload: images})
export const logoutAction = () => ({type: LOGOUT})
export const toggleGalleryImagesAction = (imageUid, typeToggle) => ({type: TOGGLE, payload: {imageUid, typeToggle}})
export const addToCartAction = (imageId) => ({type: ADD_TO_CART, payload: imageId})
export const removeFromCartAction = (imageId) => ({type: REMOVE_FROM_CART, payload: imageId})