import React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { myConfig as api } from "../../../config/config";
import Spinner from "../../../Components/Spinner/Spinner";

import "./Cooperation.css";
import { closeAllModals } from "../../../actions/modal";
const Cooperation = () => {

  const dispatch = useDispatch()
  const isCooperationActive = useSelector(state => state.modal.types.cooperation_modal);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState('');

  const remindDetails = async () => {

    try {

      setSuccess(true)
      setLoading(true)
      const response = await axios.post(`${api.serverURI}/api/user/cooperation/remind`, {name, email})

      switch(response.data.status) {
        case 'ok': {
          setResult('Ваша заявка успешно отправлена')
          setLoading(false)
          setName('')
          setEmail('')
          setTimeout(() => {
            setSuccess(false)
            dispatch(closeAllModals())
          }, 2000)
          return
        }
        case 'err': {
          setResult(response.data.errorMessage)
          setLoading(false)
          setName('')
          setEmail('')
          setTimeout(() => setSuccess(false), 2000)
          return
        }
        default: {
          setResult('Сервер не доступен')
          setLoading(false)
          setName('')
          setEmail('')
          setTimeout(() => setSuccess(false), 2000)
          return
        }
      }

    
    } catch (error) {
      console.log(error)
    }
  
  }

  return (
    <div className={isCooperationActive ? "cooperation-wrapper" : "cooperation-wrapper hidden"}>
      {/* Окно - Оставьте заявку */}
      <div className={!success ? "cooperation__leave-request" : "hidden"}>
        {/* Заглавие */}
        <div className="cooperation-wrapper__title-box">
          <h3 className="cooperation-wrapper__title"></h3>
        </div>
        {/* Блок - введите имя */}
        {/* <div className="cooperation-wrapper__input-name-box">
          <div className="modal-input-box">
              <label htmlFor="rcooperation-wrapper__input-name" className="cooperation-wrapper__label-name modal-label">Имя Фамилия</label><br />
              <input 
                type="text" 
                className="cooperation-wrapper__input-name modal-input" 
                id="rcooperation-wrapper__input-name" 
                name="login"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <br />
          </div>
          <div className="wrong-data-box hidden" id="cooperation-wrapper__wrong-input-name">
              <p>Поле заполнено некорректно</p>
          </div>
        </div> */}
        {/* Блок - введите телефон */}
        <div className="cooperation-wrapper__input-phone-box">
          <div className="modal-input-box">
              <label htmlFor="rcooperation-wrapper__input-phone" className="cooperation-wrapper__label-phone modal-label">Email</label><br />
              <input 
                type="text" 
                className="cooperation-wrapper__input-phone modal-input" 
                id="rcooperation-wrapper__input-phone" 
                name="phone"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <br />
          </div>
          <div className="wrong-data-box hidden" id="cooperation-wrapper__wrong-input-phone">
              <p>Поле заполнено некорректно</p>
          </div>
        </div>
        {/* Блок - Кнопка "Отправить заявку" */}
        <div className="cooperation-wrapper__btn-box">     
          <button 
            type="button" 
            className="btn-dark" 
            id="btn__cooperation-wrapper__send-request"
            onClick={() => remindDetails()}
          >
            <p>Отправить реквизиты</p>
          </button>
        </div>
      </div>
      {/* Окно - Информирование - Заявка успешно отправлена */}
      {

      }
      <div className={success ? "cooperation-wrapper__info-box" : "hidden"}>
          {
            loading
            ?
            <Spinner />
            :
            <>
              <div className="cooperation-wrapper__title-box">
                <h3 className="cooperation-wrapper__title">Заявка</h3>
              </div>
              <p>
                {result}
              </p>
            </>
          }
      </div>
      {/* Блок для балансировки текста - Ваша заявка успешно отправлена */}
      {/* <div className="cooperation-wrapper__balance-box"></div> */}
    </div>
  );
};

export default Cooperation;