import React from 'react';
import { useTimer } from 'react-timer-hook';
import { useDispatch } from 'react-redux';
import { setTimerClearAction } from '../../reducers/modal.reducer';

function Timer({ expiryTimestamp }) {

  const dispatch = useDispatch()
  const {
    totalSeconds,
    // seconds,
    // minutes,
    // hours,
    // days,
    // isRunning,
    // start,
    // pause,
    // resume,
    // restart,
  } = useTimer({ expiryTimestamp, onExpire: () => dispatch(setTimerClearAction())});

  const handleTime = (time) => {

    if (time > 60) {
      return `${Math.round(time / 60)} мин.`
    }
    return `${time} сек.`
  }

  return (
    <span>{` ${handleTime(totalSeconds)}`}</span>
  );
}

export { Timer }
