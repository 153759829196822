import React from "react";
import "./AlertStyles.css";
import imageMeditation from "../../../assets/images/image-meditation.jpg";

const Wait = () => {

  return (
    <div className="wait">
      <div className="wait__image-box">
        <img src={imageMeditation} alt="image-meditation" />
      </div>
      <div className="wait__title-box">
        <h2>Пожалуйста подождите</h2>
      </div>
    </div>
  )
}

export default Wait