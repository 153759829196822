import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Modal from "../../Modals/Modal";
import LeftBar from "../../Bars/LeftBar/LeftBar";
import Authorization from "../../Modals/Authorization/Authorization";
import BurgerMenu from "../../Modals/BurgerMenu/BurgerMenu";
import ConfirmExit from "../../Modals/ConfirmExit/ConfirmExit";
import Success from "../../Modals/Success/Success";
import Header from "../../Headers/HeaderClient";
import BottomBar from "../../Bars/BottomBar/BottomBar";
import ErrorModal from "../../Modals/ErrorModal/ErrorModal";
import Footer from "../../Footers/Footer";
import Cooperation from "../../Modals/Cooperation/Cooperation";
import GalleryProfileEdit from "../../Modals/GalleryProfileEdit/GalleryProfileEdit";
import ConfirmCartPhotoDelete from "../../Modals/ConfirmCartPhotoDelete/ConfirmCartPhotoDelete";
import PhotoCarousel from "../../Modals/PhotoCarousel/PhotoCarousel";
import "./Home.css";
import CoopRegSuccess from "../../Modals/Success/cooperationRegistration/CoopRegSuccess";

const Home = () => {
  return (
    <div className="home">
      <LeftBar />
      <div className="main" id="id-main">
        <Header />
        <Outlet />
        <BottomBar></BottomBar>
        <Footer></Footer>
      </div>
      <Modal>
        <Authorization />
        <BurgerMenu />
        <ConfirmExit />
        <Success />
        <Cooperation />
        <GalleryProfileEdit />
        <ConfirmCartPhotoDelete />
        <PhotoCarousel />
        <CoopRegSuccess />
        <ErrorModal /> 
        {/* <Error /> */}
      </Modal>
    </div>
  );
};

export default Home;
