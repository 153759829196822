const SET_COOPERATION_USER = 'SET_COOPERATION_USER'

const defaultState = {
  user: {},
}

export default function cooperationReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_COOPERATION_USER:
            return {
                ...state,
                user: {...action.payload}
            }
        default:
            return state
    }
}

// action creators
export const setCooperationUserAction = user => ({type: SET_COOPERATION_USER, payload: user})


