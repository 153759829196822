import React from "react";
import { useDispatch } from "react-redux";
import { toggleModalsAction } from "../../../../../reducers/modal.reducer";
import { setToDeleteAction, setCaruoselImagesAction, setClickedImageIndexAction } from "../../../../../reducers/image.reducer";
import { removeFromCart } from "../../../../../actions/cart";
import { myConfig as api } from "../../../../../config/config";

import imageGarbage from "../../../../../assets/images/icon-garbage.svg";
import "./CartPhotoItem.css";

const CartPhotoItem = ({ userUid, imageUid, index }) => {

  const dispatch = useDispatch();

  return (
    <div className="cart-photo-item">
      {/* Верхний блок - Фото, описание, стоимость, корзина */}
      <div className="cart-photo-item__top">
        {/* Левый блок - Фото, описание, стоимость */}
        <div className="cart-photo-item__top-left">
          {/* Левый блок - Фото */}
          <div 
            className="cart-photo-item__photo-box"
            onClick={() => {
              dispatch(setCaruoselImagesAction('cart'))
              dispatch(setClickedImageIndexAction(index))
              dispatch(toggleModalsAction("photo_carousel"))
            }}
          >
            <img 
              src={`${api.serverURI}/photos/${userUid}/${imageUid}`} 
              alt="family" />
          </div>
          {/* Левый блок - Описание, стоимость */}
          <div className="cart-photo-item__description-box">
            <div className="cart-photo-item__description-top">
              <p><span className="cart-photo-item__date">12.06.2022</span><br></br>Аквапарк Ривьера г.Казань</p>
              <p className="cart-photo-item__period-of-storage">хранится до 2 мая 2024г.</p>
            </div>
            <div className="cart-photo-item__description-bottom">
              <p>200р.</p>
            </div>
          </div>
        </div>
        <div className="cart-photo-item__top-right">
          <img 
            src={imageGarbage} 
            alt="imageGarbage"
            onClick={() => {
              dispatch(setToDeleteAction(imageUid))
              dispatch(toggleModalsAction("confirm_cart_photo_delete"))
            }}   
          />
        </div>
      </div>
      {/* Разделительная линия внизу */}
      <div className="cart-photo-item__line">

      </div>
    </div>
  );
};

export default CartPhotoItem;