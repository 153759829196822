import Image from "../Image/Image";
import React from "react";
import { useSelector } from "react-redux";
import "./Place.css"

const Place = ({tab}) => {
  const user = useSelector((state) => state.user);
  const images = user[tab]

  return (
    <div className="place">
      {/* Элемент с фотографиями (Раньше над ним был текст Места сделанных фотографий) */}
      <div className="place__photos-box">
        {
          user.isAuth 
          ?
          images.map((image, index) => <Image imageUid={image} userUid={user.uid} key={index} index={index} type={tab}/>)
          :
          null
        }
      </div>
    </div>
  );
}

export default Place;