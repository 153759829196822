import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {Timer} from '../../../Timer/Timer';
import { myConfig as api } from "../../../../config/config";
import { setGetOtpButtonAction, closeAllModalsAction } from '../../../../reducers/modal.reducer'
import { signIn } from '../../../../actions/user'


const OtpBox = () => {

    const dispatch = useDispatch()
    const [otp, setOtp] = useState(new Array(4).fill(''));
    const [wrongCode, setWrongCode] = useState(false);
    const otpBoxDisabled = useSelector(state => state.modal.auth.otpBox.disabled);
    const getOtpButtonRetryDisabled = useSelector(state => state.modal.auth.getOtpButtonRetry.disabled)
    const timer = useSelector(state => state.modal.auth.timer);
    const type = useSelector(state => state.modal.auth.input.type);
    const login = useSelector(state => state.modal.auth.input.login);
    let time;
    if (timer.active) {
        time = new Date();
        time.setSeconds(time.getSeconds() + (Math.round((timer.delay - Date.now()) / 1000)));
    }

    useEffect(() => {
        if (!otp.includes('')) {

            // change state attemts



            const otpHandled = otp.map(String).join('');
            axios.post(`${api.serverURI}/api/user/otp`, {login, otp: otpHandled, type})
             .then(response => {
                 if (response.status === 200) {
                    setWrongCode(false);
                    setOtp(new Array(4).fill(''));
                    dispatch(signIn(response.data.user, response.data.token))
                    dispatch(closeAllModalsAction())
                 }

                 if (response.status === 201) {
                    setWrongCode(true)
                    console.log(response.status)
                    console.log(response.data)
                 }
             }).catch(error => {
                 console.log(error)
             })
        }
    }, [otp])
    

    const getOtpCodeRetry = async () => {
        try {
  
          const response = await axios.post(`${api.serverURI}/api/user/authorization`, {login, type})
    
          if ([201, 202].includes(response.status)) {
            setOtp(new Array(4).fill(''));
            setWrongCode(false);
            dispatch(setGetOtpButtonAction(response.data.timer))
            return
          }
  
          if ([500, 505].includes(response.status)) {
            console.log(response.data)
            return
          }
        
        } catch (error) {
          console.log(error)
        } 
    }
    
    const handleOtpInputChange = async (event, index) => {
        // value must be a number
        if (isNaN(event.target.value)) return false;
        // update state by current index
        setOtp([...otp.map((item, i) => (i === index ? event.target.value : item))]);
        // focus next input
        if (event.target.value && event.target.nextElementSibling) {
          event.target.nextElementSibling.focus();
        }
    }
    const handleOtpInputKeyDown = async (event, index) => {
        // focus prev input
        if (event.key === 'Backspace' && event.target.previousElementSibling && event.target.value === '') {
            event.target.previousElementSibling.focus();
        }
    }

    return (
        <div className={!otpBoxDisabled ? "authorization__enter-code-box" : "authorization__enter-code-box hidden"}>
            <h3 className="modal-title-2">Введите полученный код</h3>
            {/* Поля для ввода кода */}
            <div className="authorization__confirm-code-box">
                {
                otp.map((data, index) => {
                    return <input 
                    type="number"
                    key={index}
                    className="modal-code-input"
                    maxLength="1"
                    autoComplete="new-item"
                    value={data}
                    onChange={(e) => handleOtpInputChange(e, index)}
                    onKeyDown={(e) => handleOtpInputKeyDown(e, index)}
                    />
                })
                }
            </div>
            <div className={wrongCode && !otp.includes('') ? "wrong-data-box" : "wrong-data-box hidden"} id="authorization__wrong-code">
                <p>Неправильный код</p>
            </div>
            <div className="authorization__counter-box">
                <p>Выслать повторно через: 
                <span id="authorization__counter">
                    {timer.active ? <Timer expiryTimestamp={time}/> : ' --:--'}
                </span>
                </p>
            </div>
            <div className="authorization__btn-again-code-box">
                {/* Для применения стиля Неактивной кнопки: добавить в класс btn-disabled и убрать btn-dark-to-light */}
                <button 
                type="button" 
                className={getOtpButtonRetryDisabled || timer.active ? 'btn-dark btn-disabled' : 'btn-dark'} 
                id="rauthorization__form-bid-btn-submit"
                disabled={getOtpButtonRetryDisabled || timer.active ? true : false}
                onClick={getOtpCodeRetry}
                >
                    <p>Выслать повторно</p>
                </button>
            </div>
            <div className="authorization__code-info-box" id="authorization__code-info-box-phone">
                <p>
                Если не приходит код, проверьте корректность введённых данных Email \ Телефон или <button><p>обратитесь в поддержку.</p></button>
                </p>
            </div>
        </div>
    )
}

export default OtpBox