import axios from "axios";
import { myConfig as api } from '../config/config'
import { toggleGalleryImagesAction } from "../reducers/user.reducer";

const token = `Bearer ${localStorage.getItem('token')}`

export const toggleGalleryImages = (userUid, imageUid, type) => {
   return async (dispatch) => {
      try {
          const response = await axios.post(`${api.serverURI}/api/image/toggle-gallery`, {token, imageUid, userUid, type})

          switch(response.data.status) {
            case 'ok': return dispatch(toggleGalleryImagesAction(imageUid, type))
            case 'err': return console.log('SERVER is not responding!')
            default: return 'Incorrect DATA'
          }

      } catch(e) {
        console.log(`ERROR - toggleGalleryImages, type - ${type}`)
      }
   }
}


