const SET_TO_DELETE = 'SET_TO_DELETE'
const CLEAR_TO_DELETE = 'CLEAR_TO_DELETE'
const SET_CAROUSEL_IMAGES = 'CAROUSEL_IMAGES'
const DEL_CAROUSEL_IMAGES = 'DEL_CAROUSEL_IMAGES'

const defaultState = {
  toDelete: '',
  carouselImages: '',
  clickedImageIndex: undefined
}

export default function imageReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_TO_DELETE:
            return {
                ...state,
                toDelete: action.payload
            }
        case CLEAR_TO_DELETE:
            return {
                ...state,
                toDelete: ''
            }

        case SET_CAROUSEL_IMAGES:
            return {
                ...state,
                carouselImages: action.payload
            }
        case DEL_CAROUSEL_IMAGES:
            return {
                ...state,
                carouselImages: ''
            }
        case 'SET_CLICKED_IMAGE_INDEX':
            return {
                ...state,
                clickedImageIndex: action.payload
            }
        default:
            return state
    }
}

// action creators
export const setToDeleteAction = id => ({type: SET_TO_DELETE, payload: id})
export const clearToDeleteAction = () => ({type: CLEAR_TO_DELETE})
export const setCaruoselImagesAction = (type) => ({type: SET_CAROUSEL_IMAGES, payload: type})
export const delCarouselImagesAction = () => ({type: DEL_CAROUSEL_IMAGES})
export const setClickedImageIndexAction = (index) => ({
    type: 'SET_CLICKED_IMAGE_INDEX',
    payload: index
})

