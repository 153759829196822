const CLOSE_ALL = 'CLOSE_ALL'
const TOGGLE_MODAL = 'TOGGLE_MODAL'
const SET_AUTH_INPUT = 'SET_INPUT'
const SET_GET_OTP_BUTTON = 'SET_GET_OTP_BUTTON'
const SET_TIMER_CLEAR = 'SET_TIMER_CLEAR'
const SET_ERROR = 'SET_ERROR'

const defaultState = {
  active: false,
  error: null,
  types: {
    modal: false,
    burger: false,
    confirm: false,
    success: false,
    galleryprofile: false,
    cooperation_modal: false,
    confirm_cart_photo_delete: false,
    photo_carousel: false,
    api_error: false,
    coop_reg_success: false,
    error_payment_modal: false,
  },
  auth: {
    input: { login: null, type: null, checked: false },
    getOtpButton: { disabled: false },
    getOtpButtonRetry: { disabled: true },
    otpBox: { disabled: true },
    timer: { active: false, delay: 0 },
    //! get attempts from back
    requestOtp: { attempts: 20 },
    errorBox: { disabled: true },
    changeInputAttempts: 10
  }
}

export default function modalReducer(state = defaultState, action) {
  switch (action.type) {
    case CLOSE_ALL:
      return {
        ...state,
        active: false,
        error: null,
        types: {
          ...defaultState.types
        }
      }
    case TOGGLE_MODAL:
      return {
        ...state,
        active: true,
        types: {
          ...state.types,
          [action.payload]: true
        }
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.payload
      }
    case SET_AUTH_INPUT:
      return {
        ...state,
        auth: {
          ...state.auth,
          input: action.payload,
        }
      }
    case SET_GET_OTP_BUTTON:
      return {
        ...state,
        auth: {
          ...state.auth,
          getOtpButton: { disabled: true },
          getOtpButtonRetry: { disabled: true },
          timer: { active: true, delay: action.payload },
          otpBox: { disabled: false },
          changeInputAttempts: state.auth.changeInputAttempts - 1
        }
      }
    case SET_TIMER_CLEAR:
      return {
        ...state,
        auth: {
          ...state.auth,
          timer: { active: false, delay: 0 },
          getOtpButtonRetry: { disabled: false },
        }
      }

    default:
      return state
  }
}

export const closeAllModalsAction = () => ({type: CLOSE_ALL})
export const toggleModalsAction = type => ({type: TOGGLE_MODAL, payload: type})
export const setAuthInputAction = checked => ({type: SET_AUTH_INPUT, payload: checked})
export const setGetOtpButtonAction = (timer) => ({type: SET_GET_OTP_BUTTON, payload: timer})
export const setTimerClearAction = () => ({type: SET_TIMER_CLEAR})
export const setErrorAction = error => ({type: SET_ERROR, payload: error})