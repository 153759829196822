import React, { useEffect, useState } from "react";

import { setUserAction } from '../../../../reducers/user.reducer'

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { myConfig as api } from "../../../../config/config";
import SelfyCam from "../../../SelfyCam/SelfyCam";
import imageFaceSample from "../../../../assets/images/image-face-sample.jpg";
import imagePhotoPortrait from "../../../../assets/images/image-photo-portrait.jpg";
import imagePointsAroundPhotoPart1 from "../../../../assets/images/image-points-around-photo-step-1v3.jpg";
import imagePointsAroundPhotoPart2 from "../../../../assets/images/image-points-around-photo-step-2v3.jpg";
import imagePointsAroundPhotoPart3 from "../../../../assets/images/image-points-around-photo-step-3v3.jpg";
import imageMeditation from "../../../../assets/images/image-meditation.jpg";
import imageCheckMark from "../../../../assets/images/image-check-mark.jpg";
import imageCross from "../../../../assets/images/image-cross.jpg";
import "./FindSelf.css";

const FindSelf = () => {

  const [startScan, setStartScan] = useState(false);
  const [waitLoad, setWaitLoad] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const [image, setImage] = useState();
  const navigate = useNavigate();
  const uid = useSelector(state => state.user.uid)
  const dispatch = useDispatch();

  useEffect(() => {
    if (image) {
      axios.post(`${api.serverREC}/api/find`, {selfy: image, uid})
      .then((result) => {
        if (result.status === 200) {
          console.log("Message was sent")
          console.log(result.data)
          dispatch(setUserAction(result.data.user))
          setWaitLoad(false)
          setSuccess(true)
          setTimeout(() => {
            navigate('/gallery')
          }, 2000)
        }
      }).catch((err) => console.log(err.message))
    }
  }, [image])

  return (
    <div className="findself">
      {
        waitLoad || success || error
        ?
        <>
          {/* Блок - Пожалуйста подождите */}
          <div className={waitLoad ? "findself__wait" : "hidden"}>
            <div className="findself__wait-image-box">
              <img src={imageMeditation} alt="image-meditation" />
            </div>
            <div className="findself__wait-title-box">
              <h2>Пожалуйста подождите</h2>
            </div>
          </div>
          {/* Блок - Сканирование завершено */}
          <div className={success ? "findself__done" : "hidden"}>
            <div className="findself__done-image-box">
              <img src={imageCheckMark} alt="image-done" />
            </div>
            <div className="findself__done-title-box">
              <h2>Сканирование завершено</h2>
            </div>
          </div>
          {/* Блок - Сканирование не удалось */}
          {/* добавить проверку на одно лицо за раз
          если больше одного лица то ошибка с нужным текстом, чтобы пользователь мог повторить */}
          <div className={error ? "findself__failed" : "hidden"}>
            <div className="findself__failed-image-box">
              <img src={imageCross} alt="image-failed" />
            </div>
            <div className="findself__failed-title-box">
              <h2>Сканирование не удалось</h2>
            </div>
            <div className="findself__btn-box">
              <button className="btn-dark">
                <p>Начать заново</p>
              </button>
            </div>
          </div>
        </>
        :
        <div className="findself__scan"> 
          {/* Title text */}
          <div className={startScan ? "hidden" : "findself__title-box"}>
            <h2 className="findself__title">Отсканируйте лицо</h2>
          </div>
          {/* Блок образец для поиска по биометрии */}
          <div className="findself__sample-box">
            {/* Картинка графичечкая */}
            <div className={`findself__sample-graphic-box ${startScan ? 'hidden' : ''}`}>
              <img src={imageFaceSample} alt="image-face-sample" />
            </div>
            
            {/* Снимок с камеры для распознавания лица */}
            <div className={`findself__sample-real-box ${startScan ? '' : 'hidden'}`}>
              <div className="findself__image-real-conteiner">
                {startScan && <SelfyCam 
                  setStartScan={setStartScan} 
                  setError={setError} 
                  setWaitLoad={setWaitLoad}
                  setImage={setImage}
                  /> 
                }
              </div>
              {/* Варианты - кружочки вокруг фото, один показываем остальные скрыты */}
              {/* <img className={isSelfyLoaded ? "findself__points-around-step-1" : "findself__points-around-step-1 hidden"} src={imagePointsAroundPhotoPart1} alt="image-points-around-photo-1" /> */}
              {/* <img className={isSelfyDetected ? "findself__points-around-step-2" : "findself__points-around-step-2 hidden"} src={imagePointsAroundPhotoPart2} alt="image-points-around-photo-2" /> */}
              {/* <img className={isSelfySending ? "findself__points-around-step-3" : "findself__points-around-step-3 hidden"} src={imagePointsAroundPhotoPart3} alt="image-points-around-photo-3" /> */}
              {/* Кружочки вокруг фото - для теста и настройки размеров вёрстки */}
              <img className="findself__points-around-step-3" src={imagePointsAroundPhotoPart3} alt="image-points-around-photo-3" />
            </div> 
            {/* Первый вариант расположения */}
            {/* {startScan && <SelfyCam setStartScan={setStartScan} setError={setError}/> } */}
          </div>
          <div className="findself__text-box hidden">
            <div className="findself__text-graphic-box hidden">
              <p>
                Найдите себя, родных или друзей ! Наша технология распознавания найдет совпадения в базе данных.
              </p>
            </div>
            <div className={startScan ? "findself__text-step-one-box" : "hidden"}>
              {/* <p>
                Пожалуйста, дождитесь завершения процесса сканирования лица.
              </p> */}
              <p>
                Включите камеру [Вкл], Поместите лицо в круг, смотрите прямо и нажмите кнопку “СНИМОК”
              </p>
            </div>
          </div>
          <div className={startScan ? "hidden" : "findself__btn-box"}>
            <button 
              className="btn-dark"
              onClick={() => setStartScan(true)}>
              <p>Начать</p>
            </button>
          </div>
          <div className={startScan ? "hidden" : "findself__agreed-box"}>
            <p>
              Нажимая кнопку “НАЧАТЬ” Вы соглашаетесь с<br />
              <button>
                <p>условиями обработки персональных данных.</p>
              </button>
            </p>
          </div>
        </div>
      }
    </div>
  );
};

export default FindSelf;