import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { closeAllModals } from '../../../actions/modal';
import './ErrorModal.css';

const ErrorModal =({message, setServerError}) => {

  const dispatch = useDispatch()
  const isApiErrorActive = useSelector(state => state.modal.types.error_payment_modal);

  return (
    <div className={isApiErrorActive ? "api-error-wrapper" : "api-error-wrapper hidden"}>      
      <div className="api-error-wrapper__info-box">
          {
            <>
              <div className="api-error-wrapper__title-box">
                <h3 className="api-error-wrapper__title">Обработка платежа</h3>
              </div>
              <p>
                Оплата не прошла. Попробуйте позже.
              </p>
              <button 
                type="button" 
                className="btn-dark" 
                onClick={() => {
                  dispatch(closeAllModals())
                }}><p>Попробовать позже</p></button>
            </>
          }
      </div>
    </div>
  );
};

export default ErrorModal; 