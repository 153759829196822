import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { myConfig as api } from "../../../config/config";
import { toggleModals } from "../../../actions/modal";

// import video1280_720_test2 from "../../../../assets/images/video-1280_720_test2.webm";
import imageEmail from "../../../../src/assets/images/icon-email.png";
import imageRefresh from "../../../../src/assets/images/icon-refresh.png";
import imageWatch from "../../../../src/assets/images/icon-watch.png";
import imageAttention from "../../../../src/assets/images/icon-attention.png";

import "./ConfirmEmailReg.css";

const ConfirmEmailReg = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [wrongCode, setWrongCode] = useState('');
  const login = useSelector(state => state.cooperation.user.login)

  return (
    // Компонент - Регистрация Фотографа - Шаг 2 из 2
    <div className="confirm-email-reg">
      <div className="confirm-email-reg__wrapper">
        {/* Заголовок */}
        <div className="title-description">
          <h2 className="">Регистрация фотографа</h2>
          <p className="">Шаг 2 из 2: Подтверждение e-mail</p>
          <p>Ваша регистрация практически завершена</p>
        </div>
        {/* Форма с полями и кнопками */}
        <form action="#" method="post" className="confirm-email-reg__form" onSubmit={(e) => {
          e.preventDefault();

          const formData = new FormData();

          formData.append("login", login);
          formData.append("otp", otp)
          
          fetch(`${api.serverURI}/api/user/cooperation/confirm`,{
            method: "POST",
            body: formData
          })
          .then(response => response.json())
          .then(data => {
            if (data.status === 'ok') {
              navigate('/')
              dispatch(toggleModals('coop_reg_success'))
            }

            if (data.status === 'err') {
              setWrongCode(data.errorMessage)
            }
          })
          .catch(error => {
            console.error(error)
            
          });
        }}>

          {/* Инфо текст */}
          <div className="info-box">
            <p>Для завершения регистрации введите код из письма:</p>
            <div className="image-text">
              <div className="image-box">
                <img src={imageEmail} alt="imageEmail" />
              </div>
              <p>support@imhere.studio</p>
            </div>
          </div> 

          {/* Блок - поля для ввода кода */}
          <div className="input-code-box">
            <p>Код:</p>
              <input type="text" onChange={(e) => setOtp(e.target.value)} value={otp}/>
              <p className="wrong-data-box">{wrongCode}</p>
            <div className="refresh-box">
              <div className="image-box">
                <img src={imageRefresh} alt="imageRefresh" />
              </div>
              {/* <p className="link">Повторно отправить код (через 2 минуты)</p> */}
            </div>
          </div>

          {/* Блок - Что делать */}
          <div className="what-do-box">
            <p>Что делать:</p>
            <p>1. Проверьте email.</p>
            <p>2. Введите 4-значный код из письма.</p>
          </div>

          <div className="image-text">
            <div className="image-box">
              <img src={imageWatch} alt="imageWatch" />
            </div>
            <p>Код действителен 10 минут.</p>
          </div>

          <div className="image-text">
            <div className="image-box">
              <img src={imageAttention} alt="imageAttention" />
            </div>
            <p>Не нашли письмо? Проверьте "Спам".</p>
          </div>
          
          <hr className="hr-gray"/>

          {/* Кнопки */}
          <div className="bts-box">
            <button type="button" className="btn-dark" onClick={() => navigate(-1)}><p>Назад</p></button>
            <button type="submit" className="btn-dark"><p>Подтвердить</p></button>
          </div>
          
        </form>

      </div>
    </div>
  );
}

export default ConfirmEmailReg;