import React from "react";
import "./Image.css";
import { useSelector, useDispatch } from "react-redux";
import { toggleGalleryImages } from '../../actions/image'
import { addToCart, removeFromCart } from '../../actions/cart'
import { myConfig as api } from "../../config/config";
import { setCaruoselImagesAction, setClickedImageIndexAction } from "../../reducers/image.reducer";
import { toggleModalsAction } from "../../reducers/modal.reducer";

const Image = ({ userUid, imageUid, index, type }) => {
  const dispatch = useDispatch()
  const { favorites, cart, purchased, uid,  } = useSelector(state => state.user)

  const download = async (filePath, fileName, mimeType = 'image/jpeg') => {
    const response = await fetch(filePath);
    const blob = await response.blob({type: mimeType});
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="image">
      <button className="image__btn-photo">
        {/* Картинка (фото) */}
        <div className="image__btn-photo-box">
          <img 
            src={`${api.serverURI}/photos/${userUid}/${imageUid}`} 
            alt="photo-family"
            onClick={() => {
              dispatch(setCaruoselImagesAction(type))
              dispatch(setClickedImageIndexAction(index))
              dispatch(toggleModalsAction("photo_carousel"))
            }} 
          />
        </div>
        {/* Ниже 2 чек-бокса расположенные на одном и том же месте (справа вверху). Один из них должен быть hidden */}
        {/* Чек-бокс -Выбрать фото- Справа вверху */}
        {
          purchased.includes(imageUid)
          ?
          <div className="image__checkbox-select-photo-box">
            <label className="image__checkbox-download-photo custom-checkbox-download">
              <input 
                type="checkbox" 
                name="customCheckbox" 
                className="custom-checkbox-input-download" 
                checked
                onChange={() => {
                  download(`${api.serverURI}/photos/${uid}/${imageUid}`, imageUid)
                }}
              />
              <span className="custom-checkbox-icon-download"></span>
            </label>
          </div>
          :
          <div className="image__checkbox-select-photo-box">
            <label className="image__checkbox-select-photo custom-checkbox">
              <input 
                type="checkbox" 
                name="customCheckbox" 
                className="custom-checkbox-input" 
                onChange={() => {
                  if (cart.includes(imageUid)) {
                    dispatch(removeFromCart(userUid, imageUid))
                  } else {
                    dispatch(addToCart(userUid, imageUid))
                  }
                }}
                checked={cart.includes(imageUid)}
              />
              <span className="custom-checkbox-icon"></span>
            </label>
          </div>
        }
       
        {/* Ниже 2 чек-бокса расположенные на одном и том же месте (справа внизу). Один из них должен быть hidden */}
        {/* Чек-бокс -Избранное фото- */}
        <div className="image__checkbox-select-photo-box">
          <label className="image__checkbox-favourites-photo custom-checkbox-favourites">
            <input 
              type="checkbox" 
              name="customCheckbox" 
              className="custom-checkbox-input-favourites" 
              onChange={() => dispatch(toggleGalleryImages(userUid , imageUid, 'favorites'))}
              checked={favorites.includes(imageUid)}
            />
            <span className="custom-checkbox-icon-favourites"></span>
          </label>
        </div>
        {/* Чек-бокс -Скачать- */}
        {/* <div className="image__checkbox-select-photo-box hidden">
          <label className="image__checkbox-download-photo custom-checkbox-download">
            <input type="checkbox" name="customCheckbox" className="custom-checkbox-input-download" />
            <span className="custom-checkbox-icon-download"></span>
          </label>
        </div> */}
      </button>
    </div>
  );
}

export default Image;