import { setUserAction, logoutAction } from '../reducers/user.reducer'
import { myConfig as api } from '../config/config'
import axios from 'axios'

export const signIn = (user, token) => {

    return async dispatch => {
        try {
            localStorage.setItem('token', token)
            dispatch(setUserAction(user))
        } catch (e) {
            console.log('login error')
            console.log(e)
        }
    }
}

export const logout = () => {

    return async dispatch => {
        try {
            localStorage.removeItem('token')
            dispatch(logoutAction())
        } catch (e) {
            console.log('logout error')
            console.log(e)
        }
    }
}

export const auth = () => {
    return async (dispatch) => {
        try {
    
          const token = localStorage.getItem('token')
    
          if (token !== 'null') {
            const response = await axios.post(`${api.serverURI}/api/user/authentication`, {token})

            if (response.status === 200) {
                localStorage.setItem('token', response.data.token)
                dispatch(setUserAction(response.data.user))
            } else {
                localStorage.removeItem('token')
                console.log('AUTH ERROR - remove token')
            }
            
          }
    
        } catch (e) {
    
          localStorage.removeItem('token')
          console.log('AUTH ERROR - remove token')
        }
    }
}