import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleModals } from "../../../actions/modal";
import { Link, useLocation } from "react-router-dom";
import "./BottomBar.css"
import imageIconLoupe from "../../../assets/images/icon-loupe.jpg";
import imageIconHome from "../../../assets/images/icon_home.svg";
import imageIconGallery from "../../../assets/images/icon-gallery.svg";
import imageIconProfile from "../../../assets/images/icon-profile.svg";
import imageCooperation from "../../../assets/images/icon-cooperation.svg";
import imageChat from "../../../assets/images/icon-chat.svg";
import imageIconSettings from "../../../assets/images/icon-settings.svg";

const BottomBar = () => {
  const location = useLocation();
  const isCartLocation = location.pathname === '/cart';
  const isAuth = useSelector(state => state.user.isAuth);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  return (
    <div className={isCartLocation ? "bottom-bar hidden" : "bottom-bar"}>
      <div className="bottom-bar__left">
        {/* Btn Gallery */}
        <Link to={isAuth ? "gallery" : "/"} onClick={() => {
          if (!isAuth) {
            dispatch(toggleModals('modal'))
          }
        }}>
          <button className="bottom-bar__image-box">
            <img src={imageIconGallery} alt="image-gallery" />
          </button>
        </Link>
        {/* Btn Cooperation */}
        <Link to="cooperation">
          <button className="bottom-bar__image-box">
            <img src={imageCooperation} alt="image-cooperation" />
          </button>
        </Link>
        
      </div>
      <div className="bottom-bar__centr">
        {/* Btn FindSelf */}
        <Link to={isAuth ? "findself" : "/"} onClick={() => {
          if (!isAuth) {
            dispatch(toggleModals('modal'))
          }
        }}>
          <button className="bottom-bar_search">
            <img src={imageIconLoupe} alt="image-loupe" />
          </button>
        </Link>
      </div>
      <div className="bottom-bar__right">
        {/* Btn Home */}
        {/* <Link to="/" >
          <button className="bottom-bar__image-box">
            <img src={imageIconHome} alt="image-home" />
          </button>
        </Link> */}
        {/* Contacts */}
        <Link to="contacts">
          <button className="bottom-bar__image-box">
            <img src={imageIconProfile} alt="image-contacts" />
          </button>
        </Link>
        {/* Account-Settings */}
        <Link to={isAuth ? 'account' : '/'} onClick={() => {
          if (!isAuth) {
            // dispatch(toggleModalsAction('modal'))
            dispatch(toggleModals('modal'))
          }
        }}>
          <button className="bottom-bar__image-box">
            <img src={imageIconSettings} alt="image-profile-settings" />
          </button>
        </Link>
        {/* Block for balance */}
        {/* ! Dont delete ! */}
        {/* <div className="balance-block">
          <div className="bottom-bar__image-box"></div>
        </div> */}
      </div>
    </div>
  );
}

export default BottomBar;