import React from "react";
import { useSelector, useDispatch } from "react-redux";

import "./Modal.css";
import imageBtnClose from "../../assets/images/btn-icon-close.svg";
import { closeAllModalsAction } from "../../reducers/modal.reducer";

const Modal = ({children}) => {

  const dispatch = useDispatch();

  const isModalActive = useSelector(state => state.modal.active);
  const type = useSelector(state => {
    let currentType = "";
    const types = Object
      .entries(state.modal.types)
      .map(([key, value]) => {
        if (value) {
          currentType = key;
        }
      });
    return currentType;
  });

  return (
    <div className="global-modal">
      <div className={isModalActive ? `${type} active` : `${type} hidden`} onClick={() => dispatch(closeAllModalsAction())}>
        <div className={isModalActive ? `${type}__content active` : `${type}__content`} onClick={(e) => e.stopPropagation()}>
          <div className="close-btn-box">
            <button onClick={() => dispatch(closeAllModalsAction())}>
              <img src={imageBtnClose} alt="image-close" />
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;