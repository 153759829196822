export const myConfig = {
   // serverURI: "http://localhost:4000",
   // serverREC: "http://localhost:5001",

   serverURI: "https://api.imhere.studio",
   serverREC: "https://rec.imhere.studio",
};

export const config = {
   width: 854,
   height: 480,
   // запрашиваем фронтальную камеру
   facingMode: "user",
}

export const dtConfig = {
   width: config.width,
   height: config.height,
   scale: 0.8
}