import React, {useState, useEffect} from "react";
import { useSelector } from 'react-redux'
import "./Payment.css";

import imageSbp from "../../../../../assets/images/icon-sbp.png";
import imageVisaMastercard from "../../../../../assets/images/icon-visa-mastercard.png";
import imageQRCode from "../../../../../assets/images/icon-qr-code.svg";
import imageYouMoney from "../../../../../assets/images/icon-you-money.png";
import imageMastercard from "../../../../../assets/images/icon-mastercard.png";
import imageVisa from "../../../../../assets/images/icon-visa.png";
import imageMir from "../../../../../assets/images/icon-mir.png";

const Payment = () => {
  const user = useSelector(state => state.user)
  const [paymentMethod, setPaymentMethod] = useState('card')
  const length = user.cart ? user.cart.length : 0

  return (
    <div className="payment">
      {/* Блок - общее количество фото и стоимость */}
      <div className="payment__info">
        <p>В корзине фоторафий: <span className="payment__number-of-photos">{length}</span></p>
        <p>Итоговая стоимось: <span className="payment__total-cost">{length * 200} рублей.</span></p>
      </div>
      {/* Блок - способы оплаты */}
      <div className="payment__method">
        <p>Выбор способа оплаты:</p>
        {/* Вариант оплаты - СБП */}
        <div className="payment__method-item disabled">
          {/* Чек-бокс - Выбрать метод */}
          <div className="image__checkbox-select-box">
            <label className="image__checkbox-select-method custom-checkbox">
              <input 
                type="radio" 
                name="paymentMethod" 
                className="custom-checkbox-input" 
                // checked={paymentMethod === 'spb'}
                // onChange={() => setPaymentMethod('spb')}
                disabled
              />
              <span className="custom-checkbox-icon"></span>
            </label>
          </div>
          {/* Название */}
          <div className="payment__method-item-name-box">
            <p>Система быстрых платежей</p>
          </div>
          {/* Картинка */}
          <div className="payment__method-item-image-box">
            <img src={imageSbp} alt="image-sbp" />
          </div>
        </div>
        {/* Вариант оплаты - Банковская карта */}
        <div className="payment__method-item">
          {/* Чек-бокс - Выбрать метод */}
          <div className="image__checkbox-select-box">
            <label className="image__checkbox-select-method custom-checkbox">
              <input 
                type="radio" 
                name="paymentMethod" 
                className="custom-checkbox-input" 
                checked
              />
              <span className="custom-checkbox-icon"></span>
            </label>
          </div>
          {/* Название */}
          <div className="payment__method-item-name-box">
            <p>Банковская карта</p>
          </div>
          {/* Картинка */}
          <div className="payment__method-item-image-box">
            <img src={imageVisaMastercard} alt="image-card" />
          </div>
        </div>
        {/* Вариант оплаты - QR-код */}
        <div className="payment__method-item disabled">
          {/* Чек-бокс - Выбрать метод */}
          <div className="image__checkbox-select-box">
            <label className="image__checkbox-select-method custom-checkbox">
              <input 
                type="radio" 
                name="paymentMethod" 
                className="custom-checkbox-input" 
                disabled
              />
              <span className="custom-checkbox-icon"></span>
            </label>
          </div>
          {/* Название */}
          <div className="payment__method-item-name-box">
            <p>QR-код</p>
          </div>
          {/* Картинка */}
          <div className="payment__method-item-image-box">
            <img src={imageQRCode} alt="image-qr-code" />
          </div>
        </div>
        {/* Вариант оплаты - YouMoney */}
        <div className="payment__method-item disabled">
          {/* Чек-бокс - Выбрать метод */}
          <div className="image__checkbox-select-box">
            <label className="image__checkbox-select-method custom-checkbox">
              <input 
                type="radio" 
                name="paymentMethod" 
                className="custom-checkbox-input" 
                disabled
              />
              <span className="custom-checkbox-icon"></span>
            </label>
          </div>
          {/* Название */}
          <div className="payment__method-item-name-box">
            <p>YouMoney</p>
          </div>
          {/* Картинка */}
          <div className="payment__method-item-image-box">
            <img src={imageYouMoney} alt="image-you-money" />
          </div>
        </div>
      </div>
      {/* Блок - реквизиты в зависимости от выбранного способа оплаты */}
      {
        paymentMethod === 'sdfsdfdsf'
        ?
        <div className="payment__requisites">
          {/* Реквизиты при собосе - банковской картой */}
          <div className="payment__cards">
            <div className="payment__cards-title">
              <p>Выбранная карта:</p>
            </div>
            {/* Карта для оплаты на выбор */}
            <div className="payment__cards-item">
              {/* Left */}
              <div className="payment__cards-item-left">
                <div className="payment__cards-item-logo-box">
                  <img src={imageMastercard} alt="imageMastercard" />
                </div>
                <div className="payment__cards-item-name-bank">
                  <p>Т-Банк</p>
                </div>
              </div>
              {/* Right */}
              <div className="payment__cards-item-right">
                <div className="payment__cards-item-card-number">
                  <p>**5297</p>
                </div>
                {/* Чек-бокс - Выбрать метод */}
                <div className="image__checkbox-select-box">
                  <label className="image__checkbox-select-method custom-checkbox">
                    <input type="radio" name="paymentCard" className="custom-checkbox-input" />
                    <span className="custom-checkbox-icon"></span>
                  </label>
                </div>
              </div>
            </div>
            {/* Карта для оплаты на выбор */}
            <div className="payment__cards-item">
              {/* Left */}
              <div className="payment__cards-item-left">
                <div className="payment__cards-item-logo-box">
                  <img src={imageVisa} alt="imageVisa" />
                </div>
                <div className="payment__cards-item-name-bank">
                  <p>Сбербанк</p>
                </div>
              </div>
              {/* Right */}
              <div className="payment__cards-item-right">
                <div className="payment__cards-item-card-number">
                  <p>**3260</p>
                </div>
                {/* Чек-бокс - Выбрать метод */}
                <div className="image__checkbox-select-box">
                  <label className="image__checkbox-select-method custom-checkbox">
                    <input type="radio" name="paymentCard" className="custom-checkbox-input" />
                    <span className="custom-checkbox-icon"></span>
                  </label>
                </div>
              </div>
            </div>
            {/* Карта для оплаты на выбор */}
            <div className="payment__cards-item">
              {/* Left */}
              <div className="payment__cards-item-left">
                <div className="payment__cards-item-logo-box">
                  <img src={imageMir} alt="imageMir" />
                </div>
                <div className="payment__cards-item-name-bank">
                  <p>Банк Санкт-Петербург</p>
                </div>
              </div>
              {/* Right */}
              <div className="payment__cards-item-right">
                <div className="payment__cards-item-card-number">
                  <p>**9823</p>
                </div>
                {/* Чек-бокс - Выбрать метод */}
                <div className="image__checkbox-select-box">
                  <label className="image__checkbox-select-method custom-checkbox">
                    <input type="radio" name="paymentCard" className="custom-checkbox-input" />
                    <span className="custom-checkbox-icon"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        null
        }
    </div>
  );
};

export default Payment;