import React from "react";
import "./AlertStyles.css";
import imageTechWorks from "../../../assets/images/image-tech-works.jpg";

const Techworks = () => {
  return (
    <div className="techworks">
      <div className="techworks__image-box">
        <img src={imageTechWorks} alt="image-tech-works" />
      </div>
      <div className="techworks__title-box">
        <h2>Технические работы</h2>
      </div>
    </div>
  )
};

export default Techworks