import React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { myConfig as api } from "../../../../config/config";
import {setGetOtpButtonAction} from "../../../../reducers/modal.reducer";

const GetOtpButton = () => {

    const hidden = useSelector(state => state.modal.auth.getOtpButton.disabled);
    const checked = useSelector(state => state.modal.auth.input.checked);
    const type = useSelector(state => state.modal.auth.input.type);
    const login = useSelector(state => state.modal.auth.input.login);

    const dispatch = useDispatch();

    const getOtpCode = async () => {
        try {
  
          const response = await axios.post(`${api.serverURI}/api/user/authorization`, {login, type})
    
          if ([201, 202].includes(response.status)) {
            dispatch(setGetOtpButtonAction(response.data.timer))
            return
          }
  
          if ([500, 505].includes(response.status)) {
            return
          }
        
        } catch (error) {
          console.log(error)
        }
      
    }

    return (
        <div className={hidden ? "modal-submit-box hidden" : "modal-submit-box"}>
            {/* Для применения стиля Неактивной кнопки: добавить в класс btn-disabled и убрать btn-dark-to-light */}
            <button 
              type="button" 
              className={!checked ? 'btn-dark btn-disabled' : 'btn-dark'}
              id="rauthorization__form-bid-btn-submit"
              onClick={getOtpCode}
              disabled={!checked}
            >
              <p>Получить код</p>
            </button>
        </div>
    )
}

export default GetOtpButton