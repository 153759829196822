import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { toggleModalsAction } from "../../../reducers/modal.reducer";

import imageIconLogo from "../../../assets/images/image-logo.svg";
import imageIconLoupe from "../../../assets/images/icon-loupe.jpg";
import imageIconHome from "../../../assets/images/icon_home.svg";
import imageIconGallery from "../../../assets/images/icon-gallery.svg";
import imageIconSettings from "../../../assets/images/icon-settings.svg";
import imageIconChat from "../../../assets/images/icon-chat.svg";
import imageIconFavourites from "../../../assets/images/icon-favourites.svg";
import imageIconPhoto from "../../../assets/images/icon-photo.svg";
import imageIconCooperation from "../../../assets/images/icon-cooperation.svg";
import imageIconProfile from "../../../assets/images/icon-profile.svg";
import imageIconMenuArrow from "../../../assets/images/icon-menu-arrow.svg";
import imageIconArrowHint from "../../../assets/images/icon-arrow-classic.svg";

import "./LeftBar.css";


const LeftBar = () => {

  const [open, setOpen] = useState(true)
  const dispatch = useDispatch();
  const isAuth = useSelector(state => state.user.isAuth);

  // Эффект для добавления/удаления класса у элемента с id="id-main
  useEffect(() => {
    const mainElement = document.getElementById("id-main");
    if (mainElement) {
      mainElement.classList.toggle("main-pedding-left-small", !open);
    }
  }, [open]); // Зависимость от состояния open

  return (
    <div className={`leftbar ${open ? "" : "leftbar-collapse"}`}>
      <Link to="/" >
        <div className="menu__logo-box">
          <img src={imageIconLogo} alt="" />
        </div>
      </Link>
      {/* Button min/max width LeftBar */}
      <button className="menu__arrow-box" id="btn-left-bar-resizing" onClick={() => setOpen(!open)}>
        <img src={imageIconMenuArrow} className={`${open ? "" : "arrow-to-left"}`} alt="" />
      </button>
      <div className="menu__hint hidden">
        <div className="menu__hint__image-box">
          <img src={imageIconArrowHint} alt="image-hint" />
        </div>
        <div className="menu__hint__text-box">
          <p>нажмите лупу чтобы начать поиск фотографий</p>
        </div>
      </div>
      <div className="menu__top">
        {/* Home */}
        {/* <Link to="/" >
          <button className="menu__top__item">
            <div className="menu__item__image-box">
              <img src={imageIconHome} alt="image-menu-item" />
            </div>
            <div className="menu__item__text-box">
              <p className={`menu__item__text ${open ? "" : "hide-text"}`}>Главная страница</p>
            </div>
          </button>
        </Link> */}
        {/* Gallery */}
        <Link to={isAuth ? "gallery" : "/"} onClick={() => {
          if (!isAuth) {
            dispatch(toggleModalsAction('modal'))
          }
        }}>
          <button className="menu__top__item">
            <div className="menu__item__image-box">
              <img src={imageIconGallery} alt="image-menu-item" />
            </div>
            <div className="menu__item__text-box">
              <p className={`menu__item__text ${open ? "" : "hide-text"}`}>Галерея</p>
            </div>
          </button>
        </Link>
        {/* Account Settings */}
        <Link to={isAuth ? 'account' : '/'} onClick={() => {
          if (!isAuth) {
            dispatch(toggleModalsAction('modal'))
          }
        }}>
          <button className="menu__top__item">
            <div className="menu__item__image-box">
              <img src={imageIconSettings} alt="image-menu-item" />
            </div>
            <div className="menu__item__text-box">
              <p className={`menu__item__text ${open ? "" : "hide-text"}`}>Аккаунт</p>
            </div>
          </button>
        </Link>
        {/* Contacts Supports */}
        <Link to="contacts">
          <button className="menu__down__item">
            <div className="menu__item__image-box">
              <img src={imageIconProfile} alt="image-menu-item" />
            </div>
            <div className="menu__item__text-box">
              <p className={`menu__item__text ${open ? "" : "hide-text"}`}>Контакты</p>
            </div>
          </button>
        </Link>
        {/* Chat Widget */}
        {/* <button 
          className="menu__top__item"
          onClick={() => {
            window.dgSocialWidgetData = {
              widgetId: '4b816205-c0cf-4bd4-a6b3-d72ba6302e77',
              apiUrl: 'https://app.daily-grow.com/sw/api/v1',
            };
          }}  
        >
          <div className="menu__item__image-box">
            <img src={imageIconChat} alt="image-menu-item" />
          </div>
          <div className="menu__item__text-box">
            <p className={`menu__item__text ${open ? "" : "hide-text"}`}>Чат поддержки</p>
          </div>
        </button> */}
      </div>
      {/* <Link to="findself"> */}
      <div className="menu__center">
        <Link to={isAuth ? "findself" : "/"} onClick={() => {
          if (!isAuth) {
            dispatch(toggleModalsAction('modal'))
          }
        }}> 
          <button className="menu__center__item">
            <div className="menu__center-item__image-box">
              <img src={imageIconLoupe} alt="image-menu-item" />
            </div>
            <div className="menu__item__text-box">
              <p className={`menu__item__text ${open ? "" : "hide-text"}`}>Найти себя</p>
            </div>
          </button>
        </Link>
      </div>
      {/* </Link> */}
      <div className="menu__down">
        {/* Cooperation */}
        <Link to="cooperation">
          <button className="menu__down__item">
            <div className="menu__item__image-box">
              <img src={imageIconCooperation} alt="image-menu-item" />
            </div>
            <div className="menu__item__text-box">
              <p className={`menu__item__text ${open ? "" : "hide-text"}`}>Сотрудничество</p>
            </div>
          </button>
        </Link>
        {/* Vacancies */}
        <Link to="#">
          <button className="menu__down__item">
            <div className="menu__item__image-box">
              <img src={imageIconPhoto} alt="image-menu-item" />
            </div>
            <div className="menu__item__text-box">
              <p className={`menu__item__text ${open ? "" : "hide-text"}`}>Вакансии</p>
            </div>
          </button>
        </Link>
        {/* About us */}
        <Link to="about">
          <button className="menu__down__item">
            <div className="menu__item__image-box">
              <img src={imageIconFavourites} alt="image-menu-item" />
            </div>
            <div className="menu__item__text-box">
              <p className={`menu__item__text ${open ? "" : "hide-text"}`}>О нас</p>
            </div>
          </button>
        </Link>
        
      </div>
      
    </div>
  );
}

export default LeftBar;