import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { myConfig as api } from "../../../../config/config";
import { useNavigate } from "react-router-dom";

import Place from "../../../Place/Place";

import "./Gallery.css";

import imageCross from "../../../../assets/images/image-cross.svg";
import imageProfileCircle from "../../../../assets/images/image-profile-circle-v2.png";
import imageNewProfileCircle from "../../../../assets/images/image-newprofile-circle-v2.png";
import iconPencil from "../../../../assets/images/icon-pencil.svg";
import imageTest from "../../../../assets/images/image-family-real-1.jpg";

import imageTestHorizontal from "../../../../assets/images/image-test-horizontal.jpg";
import imageTestVertical from "../../../../assets/images/image-test-vertical.jpg";
import imageTestSquare from "../../../../assets/images/image-test-1-1.jpg";

import { toggleModalsAction } from "../../../../reducers/modal.reducer";

const Gallery = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const images = useSelector(state =>  state.user.client_images )
  const avatar = useSelector(state => state.user.avatar)
  const friends = useSelector(state => state.user.friends)
  const uid = useSelector(state => state.user.uid)
  const [tab, setTab] = useState('client_images')

  return (
    <div className="gallery">
      {/* Title + Info */}
      <div className="gallery__title-info-box">
        <h3 className="gallery__title">Моя галерея</h3>
        <p>фотографии: <span className="gallery__text-span">{images && images.length ? images.length : 0} </span>шт</p>
      </div>
      {/* Блок: Ничего не найдено попробуйте ещё */}
      {/* Если фотографий нет: gallery__title-info-box и gallery__not-found показываем, остальные блоки скрываем */}
      <div className="gallery__not-found hidden">
        <div className="gallery__not-found-wrapper">
          <div className="gallery__not-found-image-box">
            <img src={imageCross} alt="image-cross" />
          </div>
          <div className="gallery__not-found-title-box">
            <h2>Ничего не найдено попробуйте еще</h2>
          </div>
          <div className="findself__btn-box">
            <button className="btn-dark">
              <p>Начать заново</p>
            </button>
          </div>
        </div>
      </div>
      {/* Persons block */}
      <div className="gallery__persons">
        {/* Блок - Лицо номер 1 */}
        <div className="gallery__person-box" onClick={() => dispatch(toggleModalsAction("galleryprofile"))}>
          <div className="gallery__btn-person-box">
            <div className="btn-inside-box">
              <div className="gallery__person-avatar-box">
                <img src={avatar ? `${api.serverURI}/avatars/${avatar}` : imageProfileCircle} alt="client-avatar" />
              </div>
              {/* <div className="gallery__person-name-box">
                <p> Галерея 1</p>
              </div> */}
            </div>
            {/* <div className="gallery__person-rename-box">
              <button className="gallery__person-rename-btn">
                <img src={iconPencil} alt="image-pencil" />
              </button>
            </div> */}
          </div>
        </div>
        {friends && friends.map((friend, index) => (
        <div className="gallery__person-box" onClick={() => dispatch(toggleModalsAction("galleryprofile"))} key={index}>
          <div className="gallery__btn-person-box">
            <div className="btn-inside-box">
              <div className="gallery__person-avatar-box">
                <img src={avatar ? `${api.serverURI}/avatars/${friend}` : imageProfileCircle} alt="client-avatar" />
              </div>
              {/* <div className="gallery__person-name-box">
                <p> Галерея 1</p>
              </div> */}
            </div>
            {/* <div className="gallery__person-rename-box">
              <button className="gallery__person-rename-btn">
                <img src={iconPencil} alt="image-pencil" />
              </button>
            </div> */}
          </div>
        </div>
        ))}
        {/* Блок - Добавить человека */}
        <div className="gallery__person-box">
          <button className="gallery__btn-person-box" onClick={() => navigate('/findself')}>
            <div className="btn-inside-box new-person">
              <div className="gallery__person-avatar-box">
                <img src={imageNewProfileCircle} alt="image-gallery-newperson-avatar" />
              </div>
              <div className="gallery__person-name-box">
                <p> Добавить <br />человека</p>
              </div>
            </div>
          </button>
        </div>
      </div>
      {/* Кнопки: Все фото / Избранное / Оплачены */}
      <div className="gallery__btns-sections">
        {/* Кнопка - Все фото */}
        {/* У всех кроме активного раздела, должен быть класс opacity50 */}
        <div className={tab === 'client_images' ? "gallery__all-photos" : "gallery__all-photos opacity50"} onClick={() => setTab('client_images')}>
          <button className="gallery__section-btn">
            <h3>Все фото</h3>
          </button>
          {/* Чёрная полоса, чтобы скрыть - добавить класс hidden */}
          <div className={tab === 'client_images' ? "gallery__stripe-black" : "gallery__stripe-black hidden"}></div>
        </div>
        {/* Кнопка - Избранное */}
        <div className={tab === 'favorites' ? "gallery__favourites" : "gallery__favourites opacity50"} onClick={() => setTab('favorites')}>
          <button className="gallery__section-btn">
            <h3>Избранное</h3>
          </button>
          {/* Чёрная полоса, чтобы скрыть - добавить класс hidden */}
          <div className={tab === 'favorites' ? "gallery__stripe-black" : "gallery__stripe-black hidden"}></div>
        </div>
        {/* Кнопка - Оплачены */}
        <div className={tab === 'purchased' ? "gallery__paid" : "gallery__paid opacity50"} onClick={() => setTab('purchased')}>
          <button className="gallery__section-btn">
            <h3>Оплачены</h3>
          </button>
          {/* Чёрная полоса, чтобы скрыть - добавить класс hidden */}
          <div className={tab === 'purchased' ? "gallery__stripe-black" : "gallery__stripe-black hidden"}></div>
        </div>
      </div>
      {/* Пунктирная полоса */}
      <div className="gallery__stripe"></div>
      <div className="gallery__section-all-photos">
        <Place tab={tab}/>
      </div>
    </div>
  );
};

export default Gallery;