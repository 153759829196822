import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./Cart.css";
import "./CartPhotos/CartPhotos.css";
import "./CartPhotoItem/CartPhotoItem.css";

import CartPhotos from "./CartPhotos/CartPhotos";
import CartPhotoItem from "./CartPhotoItem/CartPhotoItem";
import Payment from "./Payment/Payment";

import imageLogoutv3 from "../../../../assets/images/image-logout-v3.svg";
import imageCart from "../../../../assets/images/image-cart.jpg";

const Cart = () => {

  const user = useSelector(state => state.user)
  const length = user.cart ? user.cart.length : 0

  return (
    <div className="cart">
      {/* Верхний блок (Хедер) */}
      <div className="cart__top">
        <div className="cart__top-title-box">
          <h3>Моя корзина</h3>
          <p>добавлено <span className="cart__top-title-photos-count">
            {length}</span> фотографии</p>
        </div>
      </div>
      {/* Главный раздел страницы Cart */}
      <div className="cart__content">
        {/* Если в корзине есть хоть одно фото: отображаем CartPhotos и Payment. А cart__empty класс hidden */}
        <div className="cart-photos">
          {
            length > 0
            ?
            user.cart.map((image, index)=> <CartPhotoItem key={index} userUid={user.uid} imageUid={image} index={index}/>)
            :
            <div className="cart__empty">
              <div className="cart__empty-image-box">
                <img src={imageCart} alt="image-cart" />
              </div>
              <div className="cart__empty-title">
                <h2>Ваша корзина пока что пуста</h2>
              </div>
              <div className="cart__empty-btn-box">
                <Link to='/gallery'>
                  <button className="btn-dark">
                    <p>Перейти в галерею</p>
                  </button>
                </Link>
              </div>
            </div>
          }
        </div>
        {length ? <Payment /> : null}
      </div>
    </div>
  );
};

export default Cart;