import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeAllModalsAction } from "../../../reducers/modal.reducer";
import { clearToDeleteAction } from "../../../reducers/image.reducer";
import { removeFromCart } from "../../../actions/cart";
import "./ConfirmCartPhotoDelete.css";

const ConfirmCartPhotoDelete = () => {
    const isConfirmCartPhotoDeleteActive = useSelector(state => state.modal.types.confirm_cart_photo_delete);
    const dispatch = useDispatch()
    const image = useSelector(state => state.image.toDelete)
    const user = useSelector(state => state.user)

    return (
        <div className={isConfirmCartPhotoDeleteActive ? "confirm-cart-photo-delete" : "confirm-cart-photo-delete hidden"}>
            <p>Вы уверены что хотите убрать фото из корзины?</p>
            <div className="confirm-cart-photo-delete__buttons-box">
                <button 
                    type="button" 
                    className="btn-dark" 
                    id="btn-confirm-cart-photo-delete__keep"
                    onClick={() => {
                        dispatch(closeAllModalsAction())
                    }}
                >
                    <p>Оставить</p>
                </button>
                <button 
                    type="button" 
                    className="btn-dark" 
                    id="btn-confirm-cart-photo-delete__remove"
                    onClick={() => {
                        dispatch(removeFromCart(user.uid, image))
                        dispatch(clearToDeleteAction())
                        dispatch(closeAllModalsAction())
                    }}
                >
                    <p>Убрать</p>
                </button>
            </div>
        </div>
    )
}

export default ConfirmCartPhotoDelete;