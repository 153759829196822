import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import emailValidate from "../helpers/validation/emailValidator";
import phoneValidate from "../helpers/validation/phoneValidator";

import { setAuthInputAction } from '../reducers/modal.reducer'

function useInputHandler(initialValue) {

    const [input, setInput] = useState(initialValue);
    const dispatch = useDispatch()

    const handler = ({value, emailError, phoneError}) => {

        const isIncludesEmailSymbols = value.includes('@') || value.includes('.')
        const isPhone = !isIncludesEmailSymbols && !emailValidate(value)
        const handlePhone = (phone) => ['7','8'].includes(phone[0]) ? '+7' + phone.slice(1) : phone;

        //! GUARD for to many attempts set new email


        // start state
        if (value === '') {
            setInput(state => ({...state, value, emailError: false, phoneError: false}))
            dispatch(setAuthInputAction({login: null, type: null, checked: false}))
        };
        
        // check what is not home
        if (value[1] === '4' && !isIncludesEmailSymbols) {
            setInput(state => ({...state, value, emailError: false, phoneError: true}))
            dispatch(setAuthInputAction({login: value, type: 'phone', checked: false}))
        }
    
        // check to "+" simbol before email
        if (isIncludesEmailSymbols && value[0] === '+') {
            setInput(state => ({...state, value, emailError: true, phoneError: false}))
            dispatch(setAuthInputAction({login: value, type: 'email', checked: false}))
        }
    
        // EMAIL validate
        if (isIncludesEmailSymbols && !emailValidate(value)) {
            setInput(state => ({...state, value, emailError: true, phoneError: false}))
            dispatch(setAuthInputAction({login: value, type: 'email', checked: false}))
        }
    
        if (isIncludesEmailSymbols && emailValidate(value)) {
            setInput(state => ({...state, value, emailError: false, phoneError: false}))
            dispatch(setAuthInputAction({login: value, type: 'email', checked: true}))
        }
    
        // PHONE validate
        if (isPhone && !phoneValidate(handlePhone(value)).isValid) {
            setInput(state => ({...state, value, emailError: false, phoneError: true}))
            dispatch(setAuthInputAction({login: value, type: 'phone', checked: false}))
        }
    
        if (isPhone && phoneValidate(handlePhone(value)).isValid) {
            setInput(state => ({...state, value, emailError: false, phoneError: false}))
            dispatch(setAuthInputAction({login: value, type: 'phone', checked: true}))
        }
    }

    return [input, handler];
}

export { useInputHandler };