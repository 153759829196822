import React from "react";
import { useSelector } from "react-redux";

import Input from "./inner/Input";
import OtpBox from "./inner/OtpBox";
import ErrorBox from "./inner/ErrorBox";
import Socials from "./inner/Socials";
import GetOtpButton from "./inner/GetOtpButton";
import AgreedBox from "./inner/AgreedBox";

import "./Authorization.css";

const Authorization = () => {

  const isModalMainActive = useSelector((state) => state.modal.types.modal);

  return (
    <div className={isModalMainActive ? "authorization" : "authorization hidden"}>
      <h3 className="modal-title">Авторизация</h3>
        <Socials />
        <Input />
        {/* <ErrorBox /> */}
        <GetOtpButton />
        <OtpBox />
        <AgreedBox />
    </div>
  );
};

export default Authorization;