import {applyMiddleware, combineReducers, legacy_createStore as createStore} from 'redux';
import {thunk} from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import modalReducer from './modal.reducer';
import userReducer from './user.reducer';
import imageReducer from './image.reducer';
import cooperationReducer from './cooperation.reducer'

const rootReducer = combineReducers({
  user: userReducer,
  image: imageReducer,
  modal: modalReducer,
  cooperation: cooperationReducer,
})

export const store = createStore(
  rootReducer,
  // able to use redux dev tools
  composeWithDevTools(applyMiddleware(thunk))
)