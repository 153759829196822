import React from "react";
import { useInputHandler } from "../../../../hooks/useInputHandler"
const Input = () => {

  const [input, setInput] = useInputHandler({value: '', emailError: false, phoneError: false});

  return (
    <div className="modal-input-box">
      <label htmlFor="authorization__modal-input-login" className="modal-label">E-mail \ Телефон</label><br />
      {/* Для подсветки некорректного ввода, добавить класс modal-input-wrong */}
      <input 
        type="text" 
        spellCheck="false" 
        className={input.emailError || input.phoneError ? "modal-input modal-input-wrong" : "modal-input"}
        id="authorization__modal-input-login" 
        name="login"
        value={input.value}
        onChange={(e) => setInput({value: e.target.value, emailError: input.emailError, phoneError: input.phoneError})}
      />
      <br />
    </div>
  )
}

export default Input