import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toggleGalleryImages } from "../../../actions/image";
import { addToCart, removeFromCart } from "../../../actions/cart";
import { closeAllModalsAction } from "../../../reducers/modal.reducer";
import { myConfig as api } from "../../../config/config";
import "./PhotoCarousel.css";

import Footer from "../../Footers/Footer";

import imageFamilyReal3 from "../../../assets/images/image-family-real-3.jpg";
import imageNature from "../../../assets/images/image-nature.jpg";
import imageNatureHorizontale from "../../../assets/images/image-nature-horizontale-1.jpg";
import imageArrowLeft from "../../../assets/images/icon-arrow-left.svg";
import imageArrowLeftV2 from "../../../assets/images/icon-arrow-left-v2.svg";
import imageArrowRightV2 from "../../../assets/images/icon-arrow-right-v2.svg";

const PhotoCarousel = () => {
    const dispatch = useDispatch();
    const isPhotoCarouselActive = useSelector(state => state.modal.types.photo_carousel);
    const type = useSelector(state => state.image.carouselImages)
    const user = useSelector(state => state.user)
    const location = useLocation();
    const currentLocation = location.pathname
    const images = user[type]
    const length = images ? images.length : 0
    const [currentIndex, setCurrentIndex] = useState(0)
    const currentImage = images ? images[currentIndex] : null;

    // Получаем индекс изображения, на которое нажали
    const clickedImageIndex = useSelector(state => state.image.clickedImageIndex)

    useEffect(() => {
        if (location.pathname === '/cart' && user.cart.length && user.cart.length === 0) {
            dispatch(closeAllModalsAction())
        }
    }, [location])

    // Устанавливаем начальный индекс при открытии карусели
    useEffect(() => {
        if (isPhotoCarouselActive && clickedImageIndex !== undefined) {
            setCurrentIndex(clickedImageIndex)
        }
    }, [isPhotoCarouselActive, clickedImageIndex])

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => {
            if (prevIndex === 0) {
                return length - 1; // Переходим к последнему фото
            }
            return prevIndex - 1;
        });
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => {
            if (prevIndex === length - 1) {
                return 0; // Переходим к первому фото
            }
            return prevIndex + 1;
        });
    };

    const download = async (filePath, fileName, mimeType = 'image/jpeg') => {
        const response = await fetch(filePath);
        const blob = await response.blob({type: mimeType});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    // Обработчик клавиш
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (!isPhotoCarouselActive) return;

            switch (event.key) {
                case 'ArrowLeft':
                    handlePrevious();
                    break;
                case 'ArrowRight':
                    handleNext();
                    break;
                case 'Escape':
                    dispatch(closeAllModalsAction());
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [isPhotoCarouselActive, length, dispatch]);

    return (
        <div className={isPhotoCarouselActive ? "photo-carousel" : "photo-carousel hidden"}>
            {/* Верхний блок */}
            <div className="photo-carousel__top">
                {/* <div className="photo-carousel__title-box">
                    <p><span className="photo-carousel__title-date">12.06.2022</span> Аквапарк Ривьера г.Казань - DCM094573
                    </p>
                    <div className="photo-carousel__title-dop-box">
                        <p>Добавьте фото в корзину и оплатите заказ для получения своих фотографий в оригинальном качестве и без водяных знаков.</p>
                    </div>
                </div> */}
                <div className="photo-carousel__photo-box">
                    {
                        length > 0
                        ?
                        <img 
                        src={`${api.serverURI}/photos/${user.uid}/${images[currentIndex]}`}
                        alt="imageNature" 
                        />
                        :
                        null 
                    }   
                    {/* Btn Left */}
                    <div className="btn-arrow-left-box" onClick={handlePrevious}>
                        <img 
                            className="photo-carousel__photo-arrow-left" 
                            src={imageArrowLeftV2} 
                            alt="imageArrow"
                        />
                    </div>
                    {/* Btn Right */}
                    <div className="btn-arrow-right-box" onClick={handleNext}>
                        <img 
                            className="photo-carousel__photo-arrow-right" 
                            src={imageArrowRightV2} 
                            alt="imageArrow" 
                        />
                    </div>
                    {/* Ниже 2 чек-бокса расположенные на одном и том же месте (справа вверху). Один из них должен быть hidden */}
                    {/* Чек-бокс - скачать*/}
                    {
                    currentLocation === '/cart'
                    ?
                    null
                    :
                    <>
                        {
                        user.purchased?.includes(currentImage)
                        ?
                        <div className="image__checkbox-select-photo-box">
                            <label className="image__checkbox-download-photo custom-checkbox-download">
                                <input 
                                    type="checkbox" 
                                    name="customCheckbox" 
                                    className="custom-checkbox-input-download" 
                                    checked
                                    onChange={() => download(`${api.serverURI}/photos/${user.uid}/${images[currentIndex]}`, `${images[currentIndex]}`)}
                                />
                                <span className="custom-checkbox-icon-download"></span>
                            </label>
                        </div>
                        :
                        <div className="image__checkbox-select-photo-box">
                            <label className="image__checkbox-select-photo custom-checkbox">
                                <input 
                                    type="checkbox" 
                                    name="customCheckbox" 
                                    className="custom-checkbox-input"
                                    checked={user.cart?.includes(currentImage) || false}
                                    onChange={() => {
                                        if (user.cart?.includes(currentImage)) {
                                            dispatch(removeFromCart(user.uid, currentImage))
                                        } else {
                                            dispatch(addToCart(user.uid, currentImage))
                                        }
                                    }}
                                    // checked={user.cart?.includes(currentImage) || false}
                                />
                                <span className="custom-checkbox-icon"></span>
                            </label>
                        </div> 
                        }
                    </>
                    }             
                    {/* Чек-бокс -Избранное */}
                    <div className="image__checkbox-select-photo-box">
                        <label className="image__checkbox-favourites-photo custom-checkbox-favourites">
                        <input 
                            type="checkbox" 
                            name="customCheckbox" 
                            className="custom-checkbox-input-favourites" 
                            onChange={() => dispatch(toggleGalleryImages(user.uid, currentImage, 'favorites'))}
                            checked={user.favorites?.includes(currentImage) || false}
                        />
                        <span className="custom-checkbox-icon-favourites"></span>
                        </label>
                    </div>
                </div>
                {/* <div className="photo-carousel__info-box">
                    <p>Добавьте фото в корзину и оплатите заказ для получения своих фотографий в оригинальном качестве и без водяных знаков.</p>
                </div> */}
                <div className="photo-carousel__for-balance">
                </div>
            </div>
            {/* Футер */}
            {/* <div className="photo-carousel__footer">
                <Footer />
            </div> */}
        </div>
    )
}

export default PhotoCarousel