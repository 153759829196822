import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toggleModalsAction, closeAllModalsAction } from "../../../reducers/modal.reducer";

import "./BurgerMenu.css";
import imageLogo from "../../../assets/images/image-logo.svg";
import imageLogIn from "../../../assets/images/image-login.svg";
import imageLogOut from "../../../assets/images/image-logout-v3.svg";
import imageIconPhone from "../../../assets/images/icon-phone.svg";
import imageIconFacebook from "../../../assets/images/icon-facebook.svg";
import imageIconVk from "../../../assets/images/icon-vk.svg";
import imageIconInstagram from "../../../assets/images/icon-instagram.svg";
import imageIconTelegram from "../../../assets/images/icon-telegram.svg";

const BurgerMenu = () => {

  const isBurgerMenuActive = useSelector(state => state.modal.types.burger);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isAuth = useSelector(state => state.user.isAuth)

  const nextFn = async () => {
    dispatch(closeAllModalsAction())
  }

  return (
    <div className={isBurgerMenuActive ? "burger-menu" : "burger-menu hidden"}>
      {/* Logo */}
      <Link to="/" onClick={nextFn}>
        <div className="burger-menu__logo-box">
          <img src={imageLogo} alt="image-logo" />
        </div>
      </Link>
      {/* Menu in Burger */}
      <div className="burger-menu__menu-box">
        {/* Gallery */}
        <Link to={isAuth ? "gallery" : "/"} onClick={async () => {
          if (!isAuth) {
            await nextFn()
            dispatch(toggleModalsAction('modal'))
          } else {
            await nextFn()
          }
        }}>
          <button className="burger-menu__menu-item">
            <p>Галерея</p>
          </button>
        </Link>
        {/* Find self */}
        <Link to={isAuth ? "findself" : "/"} onClick={async () => {
          if (!isAuth) {
            await nextFn()
            dispatch(toggleModalsAction('modal'))
          } else {
            await nextFn()
          }
        }}>
          <button className="burger-menu__menu-item" id="btn-burger-menu__find-yourself">
            <p>Найти себя</p>
          </button>
        </Link>
        {/* Account-settings */}
        <Link to={isAuth ? "account" : "/"} onClick={async () => {
          if (!isAuth) {
            await nextFn()
            dispatch(toggleModalsAction('modal'))
          } else {
            await nextFn()
          }
        }}>
          <button className="burger-menu__menu-item">
            <p>Аккаунт</p>
          </button>
        </Link>
        <Link to="cooperation" onClick={nextFn}>
          <button className="burger-menu__menu-item" id="btn-burger-menu__cooperation">
            <p>Сотрудничество</p>
          </button>
        </Link>
        <Link to="#" onClick={nextFn}>
          <button className="burger-menu__menu-item" id="btn-burger-menu__vacancy">
            <p>Вакансии</p>
          </button>
        </Link>
        <Link to="contacts" onClick={nextFn}>
          <button className="burger-menu__menu-item">
            <p>Контакты</p>
          </button>
        </Link>
        <Link to="about" onClick={nextFn}>
          <button className="burger-menu__menu-item" id="btn-burger-menu__about-us">
            <p>О нас</p>
          </button>
        </Link>
        
      </div>
      {/* Log In */}
      {isAuth ? 
        <div className="burger-menu__log-out-box">
          <button 
            className="btn-burger-menu__log-out" 
            id="btn-burger-menu__log-out"
            onClick={() => {
              dispatch(closeAllModalsAction())
              dispatch(toggleModalsAction('confirm'))
            }}
          >
            <div className="burger-menu__log-out-image-box">
              <img src={imageLogOut} alt="image-log-out" />
            </div>
            <div className="burger-menu__log-out-text-box">
              <p>Выход</p>
            </div>
          </button>
        </div>
        :
        <div className="burger-menu__log-in-box">
          <button 
            className="btn-burger-menu__log-in" 
            id="btn-burger-menu__log-in"
            onClick={() => {
              dispatch(closeAllModalsAction())
              dispatch(toggleModalsAction('modal'))
            }}
          >
            <div className="burger-menu__log-in-image-box">
              <img src={imageLogIn} alt="image-log-in" />
            </div>
            <div className="burger-menu__log-in-text-box">
              <p>Вход</p>
            </div>
          </button>
        </div>
      }

      {/* Contacts */}
      {/* <div className="burger-menu__contacts">
        <p>Контакты:</p>
        <div className="burger-menu__contacts-item-box">
          <a href="tel:+79998887766">
            <div className="burger-menu__contacts-item">
              <div className="burger-menu__contacts-icon-box">
                <img src={imageIconPhone} alt="icon-phone" />
              </div>
              <p>89998887766</p>
            </div>
          </a>
        </div>
        <div className="burger-menu__contacts-item-box">
          <a href="tel:+79998887766">
            <div className="burger-menu__contacts-item">
              <div className="burger-menu__contacts-icon-box">
                <img src={imageIconPhone} alt="icon-phone" />
              </div>
              <p>89998887766</p>
            </div>
          </a>
        </div>
      </div> */}
      {/* Socials */}
      {/* <div className="burger-menu__socials">
        <p>Социальные сети:</p>
        <div className="burger-menu__socials-box">
          <button className="burger-menu__socials-item">
            <img src={imageIconFacebook} alt="icon-facebook" />
          </button>
          <button className="burger-menu__socials-item">
            <img src={imageIconVk} alt="icon-vk" />
          </button>
          <button className="burger-menu__socials-item">
            <img src={imageIconInstagram} alt="icon-instagram" />
          </button>
          <button className="burger-menu__socials-item">
            <img src={imageIconTelegram} alt="icon-telegram" />
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default BurgerMenu;