import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { closeAllModals } from "../../../../actions/modal";


import "./ApiError.css";
const ApiError = ({message, setServerError}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isApiErrorActive = useSelector(state => state.modal.types.api_error);

  return (
    <div className={isApiErrorActive ? "api-error-wrapper" : "api-error-wrapper hidden"}>      
      <div className="api-error-wrapper__info-box">
          {
            <>
              <div className="api-error-wrapper__title-box">
                <h3 className="api-error-wrapper__title">Обработка</h3>
              </div>
              <p>
                {[message]}
              </p>
              <button 
                type="button" 
                className="btn-dark" 
                onClick={() => {
                  dispatch(closeAllModals())
                  setServerError('')
                  navigate('/')
                }}><p>Попробовать позже</p></button>
            </>
          }
      </div>
    </div>
  );
};

export default ApiError;