import React from "react";
import { useSelector, useDispatch } from "react-redux";

import "./CoopRegSuccess.css";
import { closeAllModals } from "../../../../actions/modal";
const CoopRegSuccess = () => {

  const dispatch = useDispatch()
  const isCooperationActive = useSelector(state => state.modal.types.coop_reg_success);

  return (
    <div className={isCooperationActive ? "coop-reg-success-wrapper" : "cooperation-wrapper hidden"}>
      <div className="coop-reg-success-wrapper__info-box">
          <p>
            Регистрация завершена успешно. Реквизиты для подключения отправлены на указанный почтовый адрес.
          </p>
      </div>
      <button type="submit" className="btn-dark" onClick={() => dispatch(closeAllModals())}><p>Понял</p></button>
    </div>
  );
};

export default CoopRegSuccess;