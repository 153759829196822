import React from "react";

const Undefined = () => {
  return (
    <div>
      <h1>404</h1>
      <p>Страница не найдена</p>
    </div>
  );
};

export default Undefined;