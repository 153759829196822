import "./Purchase.css";

import imageReceipt from "../../../../assets/images/image-receipt.svg";
import imagePhotoFilmV2 from "../../../../assets/images/image-photo-film-v2.svg";
import imageCross from "../../../../assets/images/btn-icon-close.svg";
import imageCardCross from "../../../../assets/images/image-card-cross.svg";

export default function Purchase() {
    return (
        <div className="purchase">
            {/* Показывает при успешной оплате */}
            <div className="successful-payment">
                <div className="successful-payment__wrapper">
                    <div className="successful-payment__title">
                        <h2>Заказ успешно оплачен!</h2>
                    </div>
                    {/* Контент */}
                    <div className="successful-payment__content">
                        {/* Левый блок - Получить чек */}
                        <div className="successful-payment__left">
                            {/* Блок ввода почты */}
                            <div className="successful-payment__left-input-box">
                                <div className="successful-payment__left-input-box-wrapper">
                                    {/* Блок - введите эл. почту */}
                                    <div className="modal-input-box">
                                        <label htmlFor="successful-payment__modal-input-e-mail" 
                                        className="modal-label">E-mail</label><br />
                                        {/* Для подсветки некорректного ввода, добавить класс modal-input-wrong */}
                                        <input type="text" spellCheck="false" className="modal-input"
                                            id="successful-payment__modal-input-e-mail" name="e-mail"
                                            placeholder="email@gmail.com"/>
                                        <br />
                                    </div>
                                    {/* Показать при некорректно введенных данных в поле input */}
                                    <div className="wrong-data-box hidden">
                                        <p>Не корректные данные</p>
                                    </div>
                                </div>
                            </div>
                            {/* Кнопка - Получить электронный чек*/}
                            <div className="successful-payment__left-button-box">
                                {/* Для Неактивной кнопки: добавляем элементу класс btn-disabled */}
                                <button className="btn-dark">
                                    <p>Получить электронный чек</p>
                                </button>
                            </div>
                            {/* Блок - информирование об отправленном чеке */}
                            {/* Для информирования отображаем этот блок. */}
                            <div className="text-info-box like-modal-blur hidden">
                                <div className="btn-close-box">
                                    <img src={imageCross} className="opacity30" alt="imageCross" />
                                </div>
                                <p>
                                    Электронный чек отправлен <br></br>на ваш почтовый адрес.
                                </p>
                                <div className="balance-box"></div>
                            </div>
                        </div>
                        {/* Правый блок - Перейти в галерею */}
                        <div className="successful-payment__right">
                            <div className="successful-payment__right-image-box">
                                <img src={imagePhotoFilmV2} alt="imagePhotoFilmV2" />
                            </div>
                            <div className="successful-payment__right-button-box">
                                {/* Для Неактивной кнопки: добавляем элементу класс btn-disabled */}
                                <button className="btn-dark">
                                    <p>Перейти в галерею и скачать</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Показывает при Не успешной оплате */}
            <div className="failed-payment hidden">
                <div className="failed-payment__wrapper">
                    <div className="failed-payment__image-box">
                        <img src={imageCardCross} alt="imageCardCross" />
                    </div>
                    <div className="failed-payment__text-box">
                        <h2>Не удалось оплатить <span className="failed-payment__text-span"> покупку</span></h2>
                    </div>
                    <div className="failed-payment__btn-box">
                        <button className="btn-dark">
                            <p>Попробовать снова</p>
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
    )
}