import React, { useState, useEffect, useRef} from "react";
import { ReactComponent as Facingicon } from "./facingIcon.svg";
import * as tf from "@tensorflow/tfjs";
import * as facemesh from "@tensorflow-models/facemesh";
import { drawMesh } from "../../helpers/recognize/recScript";
import Webcam from "react-webcam";
import {myConfig} from "../../config/config";
import "./SelfyCam.css";

export default function SelfyCam({setStartScan, setError, setImage, setWaitLoad}) {

    const [isEnabled, setIsEnabled] = useState(false);
    const [facing, setFacing] = useState("user");
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);
    const button = useRef(null);

    // const runDetector = async () => {
    //     const detector = await facemesh.load(myConfig.dtConfig);
    //     const detect = async (net) => {
    //       if(webcamRef.current && webcamRef.current.video.readyState === 4) {
            
    //         // подгоняем размер видео и канвас чтобы они идеально совпадали 
    //         const video = webcamRef.current.video;
    //         const canvas = canvasRef.current;
    //         canvas.width = video.videoWidth;
    //         canvas.height = video.videoHeight;
    //         const face = await net.estimateFaces(video);
    //         const ctx = canvas.getContext("2d");
    //         requestAnimationFrame(() => drawMesh(face, ctx));
    //         detect(detector);
    //       }
    //     }
    //     detect(detector);
    // }

    // useEffect(() => {
    //   setIsEnabled(true)
    //   setTimeout(() => runDetector(), 500)
      
    // }, [isEnabled]);

    const capture = () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc)
      setWaitLoad(true)
      setStartScan(false)
      setIsEnabled(false) 
    }

    return (
      <>
        <div className="ring-wrapper">
          {isEnabled && <>
            <Webcam
              ref={webcamRef} 
              audio={false}
              mirrored={facing === "user" ? true : false}
              videoConstraints={myConfig.webcam} 
              onUserMediaError={(error) => setError(error.name)}
              onUserMedia={(error) => setError(null)}
              screenshotFormat="image/jpeg"
              screenshotQuality={1}
            />
            <canvas ref={canvasRef} />
          </>}
        </div>
        <div className="selfycam-btns-box">
          <button ref={button} onClick={() => setIsEnabled(!isEnabled)}>{isEnabled ? "Выкл" : "Вкл"}</button>
          <button onClick={() => capture()}>Снимок</button>
        </div>
        {/* <button onClick={() => setFacing(facing === "user" ? "environment" : "user")}>
          <Facingicon />
        </button> */}
      </>
    )
}