import React from "react";
import "./AlertStyles.css";
import imageConnectionLost from "../../../assets/images/image-connection-lost.jpg";

const Connection = () => {
  return (
    <div className="connection">
      <div className="connection__image-box">
        <img src={imageConnectionLost} alt="image-connection-lost" />
      </div>
      <div className="connection__title-box">
        <h2>Отсутствует интернет соединение</h2>
      </div>
      <div className="connection__btn-box">
        <button className="btn-dark">
          <p>Начать заново</p>
        </button>
      </div>
    </div>
  )
}

export default Connection