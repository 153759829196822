import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleModalsAction, closeAllModalsAction } from "../../reducers/modal.reducer";

import "./HeaderClient.css"
import imageLogo from "../../assets/images/image-logo.svg";
import imageLoginDesktop from "../../assets/images/image-login.svg";
import iconBasketDesktop from "../../assets/images/icon-basket.svg";
import iconLogoutDesktop from "../../assets/images/icon-logout.svg";
import iconMenuMini from "../../assets/images/btn-icon-menu-mini-v4.svg";
import iconLoginMobile from "../../assets/images/btn-icon-login-v4.svg";
import iconBasketMobile from "../../assets/images/btn-icon-basket.svg";
import iconLogoutMobile from "../../assets/images/btn-icon-logout-v3.svg";
import iconArrowLeft from "../../assets/images/icon-arrow-classic.svg";
import { Link, useLocation } from "react-router-dom";

const Header = () => {

  const dispatch = useDispatch()
  const location = useLocation();
  const isAuth = useSelector(state => state.user.isAuth)
  const cartItems = useSelector(state => state.user.cart || [])
  const cartCount = cartItems.length
  // const isCartLocation = location.pathname === '/cart';

  return (
    <div className="header">
      <div className="header__left">
        <button 
          className="header__btn-menu" 
          id="header__btn-menu"
          onClick={() => {
            dispatch(toggleModalsAction('burger'))
          }}
        >
          <img src={iconMenuMini} alt="icon-menu-mini" />
        </button>
      </div>
      <Link to="/" onClick={() => dispatch(closeAllModalsAction())}>
        <div className="header__center">
          <img src={imageLogo} alt="image-logo" />
        </div>
      </Link>
      
      <div className="header__right">
        {/* Кнопки для планшетов и мобильных */}
        { isAuth ? 
          <>
            <Link to="cart">
              <div className="header__btn-basket-box">
                <button className="header__btn-basket" id="header__btn-basket">
                  <img src={iconBasketMobile} alt="icon-basket" />
                  {cartCount > 0 && <span className="cart-badge"><p>{cartCount}</p></span>}
                </button>
              </div>
            </Link>
            <div className="header__btn-logout-box">
              <button 
                className="header__btn-logout" 
                id="header__btn-logout"
                onClick={() => {
                  dispatch(toggleModalsAction('confirm'))
                }}
              >
                <img src={iconLogoutMobile} alt="icon-logout" />
              </button>
            </div>
          </>
          :
          <div className="header__btn-login-box">
            <button 
              className="header__btn-login" 
              id="header__btn-login"
              onClick={() => {
                dispatch(toggleModalsAction('modal'))
              }}
            >
              <img src={iconLoginMobile} alt="icon-login" />
            </button>
          </div>
        }
        {/* Кнопки для десктоп */}
        { isAuth ?
          <>
            <button 
              className="header__btn-basket-desktop" 
              id="header__btn-basket-desktop" 
            >
              <Link to="cart">
                <div className="header__btn-basket-desktop__img-box">
                  <img src={iconBasketDesktop} alt="icon-basket" />
                  {cartCount > 0 && <span className="cart-badge"><p>{cartCount}</p></span>}
                </div>
              </Link>
              <Link to="cart">
                <div className="header__btn-basket-desktop__text-box">
                  <p>Корзина</p> 
                </div>
              </Link>
            </button>
            <button 
              className="header__btn-logout-desktop" 
              id="header__btn-logout-desktop"
              onClick={() => {
                dispatch(toggleModalsAction('confirm'))
              }}
            >
              <div className="header__btn-logout-desktop__img-box">
                <img src={iconLogoutDesktop} alt="icon-logout" />
              </div>
              <div className="header__btn-logout-desktop__text-box">
                <p>Выход</p> 
              </div>
            </button>
          </>
          :
          <button 
            className="header__btn-login-desktop" 
            id="header__btn-login-desktop"
            onClick={() => {
              dispatch(toggleModalsAction('modal'))
            }}
          >
            <div className="header__btn-login-desktop__img-box">
              <img src={imageLoginDesktop} alt="image-login" />
            </div>
            <div className="header__btn-login-desktop__text-box">
              <p>Вход</p>
            </div>
          </button>
        }
      </div>
      {/* <div className="header__btn-back opacity30">
        <img src={iconArrowLeft} alt="iconArrowLeft" />
      </div> */}
    </div>
  );
}

export default Header;