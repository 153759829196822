import React from "react";
import "./Account.css";

import imagePencil from "../../../../assets/images/icon-pencil.svg";
import imageProfileSilhouette from "../../../../assets/images/icon-profile-silhouette.svg";
import imageMastercard from "../../../../assets/images/icon-mastercard.png";
import imageGarbage from "../../../../assets/images/icon-garbage.svg";
import imageVisa from "../../../../assets/images/icon-visa.png";
import imageMir from "../../../../assets/images/icon-mir.png";
import imageCross from "../../../../assets/images/image-cross.jpg";

const Account = () => {
  return (
    <div className="account">
      {/* Title box */}
      <div className="account__title-info-box">
        <h3>Настройки аккаунта</h3>
      </div>
      {/* Главный контент */}
      <div className="account__place">
        {/* Фото профиля */}
        <div className="account__avatar-box">
          {/* Бокс 131 на 106 пикс */}
          <div className="account__avatar-wrapper">
            {/* Нижний слой - Меню - карандаш */}
            <div className="account__avatar-mini-menu-box">
              <img src={imagePencil} alt="imagePencil" />
            </div>
            <div className="account__avatar-photo-box">
              <img src={imageProfileSilhouette} alt="imageProfileSilhouette" />
            </div>
          </div>
        </div>
        {/* Главная часть страницы */}
        <div className="account__place-center">
          {/* 1 - Данные пользователя (Имя, телефон и т.д.) */}
          <div className="account__dates">
            {/* Блок - Имя и Фамилия */}
            <div className="account__name-lastname">
              {/* Блок - введите Имя и Фамилию */}
              <div className="modal-input-box">
                <label htmlFor="account__input-name-lastname" 
                className="modal-label">Имя и Фамилия</label><br />
                {/* Для подсветки некорректного ввода, добавить класс modal-input-wrong */}
                <div className="input-wrapper">
                  <input type="text" spellCheck="false" className="modal-input"
                      id="account__input-name-lastname" name="name"
                      placeholder="Добавьте имя и фамилию"/>
                  <div className="input-pencil-box opacity30">
                    <img src={imagePencil} alt="imagePencil" />
                  </div>
                </div>
              </div>
              {/* Показать при некорректно введенных данных в поле input */}
              <div className="wrong-data-box hidden">
                  <p>Не корректные данные</p>
              </div>
            </div>
            {/* Блок - Дата рождения и Пол */}
            <div className="account__second-string">
              {/* Блок - Дата рождения */}
              <div className="account__birthday-box">
                {/* Блок - введите Дату рождения */}
                <div className="modal-input-box">
                  <label htmlFor="account__input-birthday" 
                  className="modal-label">Дата рождения</label><br />
                  {/* Для подсветки некорректного ввода, добавить класс modal-input-wrong */}
                  <div className="input-wrapper">
                    <input type="date" spellCheck="false" className="modal-input"
                        id="account__input-birthday" name="name"
                        placeholder="дд.мм.гггг"/>
                    <div className="input-pencil-box opacity30 hidden">
                      <img src={imagePencil} alt="imagePencil" />
                    </div>
                  </div>
                </div>
                {/* Показать при некорректно введенных данных в поле input */}
                <div className="wrong-data-box hidden">
                    <p>Не корректные данные</p>
                </div>
              </div>
              <div className="account__gender-box">
                <p>Пол</p>
                <div className="account__gender-wrapper">
                  {/* Пол - Мужской */}
                  <div className="account__gender-item">
                    <div className="checkbox__wrapper">
                      {/* Чек-бокс */}
                      <label className="custom-checkbox">
                        <input type="radio" name="customCheckbox-gender" className="custom-checkbox-input" />
                        <span className="custom-checkbox-icon"></span>
                      </label>
                      <p>Муж.</p>
                    </div>
                  </div>
                  {/* Пол - Женский */}
                  <div className="account__gender-item">
                    <div className="checkbox__wrapper">
                      {/* Чек-бокс */}
                      <label className="custom-checkbox">
                        <input type="radio" name="customCheckbox-gender" className="custom-checkbox-input" />
                        <span className="custom-checkbox-icon"></span>
                      </label>
                      <p>Жен.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Блок - Телефон */}
            <div className="account__phone">
              <div className="modal-input-box">
                <label htmlFor="account__input-phone" 
                className="modal-label">Номер телефона</label><br />
                {/* Для подсветки некорректного ввода, добавить класс modal-input-wrong */}
                <div className="input-wrapper">
                  <input type="tel" spellCheck="false" className="modal-input"
                      id="account__input-phone" name="phone"
                      placeholder="+7 (999) 888 77 66"/>
                  <div className="input-pencil-box opacity30">
                    <img src={imagePencil} alt="imagePencil" />
                  </div>
                </div>
              </div>
              {/* Показать при некорректно введенных данных в поле input */}
              <div className="wrong-data-box hidden">
                  <p>Не корректные данные</p>
              </div>
            </div>
            {/* Блок - E-mail */}
            <div className="account__email">
              <div className="modal-input-box">
                <label htmlFor="account__input-email" 
                className="modal-label">Адрес E-mail</label><br />
                {/* Для подсветки некорректного ввода, добавить класс modal-input-wrong */}
                <div className="input-wrapper">
                  <input type="email" spellCheck="false" className="modal-input"
                      id="account__input-email" name="email"
                      placeholder="imhere@gmail.com"/>
                  <div className="input-pencil-box opacity30">
                    <img src={imagePencil} alt="imagePencil" />
                  </div>
                </div>
              </div>
              {/* Показать при некорректно введенных данных в поле input */}
              <div className="wrong-data-box hidden">
                  <p>Не корректные данные</p>
              </div>
            </div>
          </div>
          {/* Разделительная линия */}
          <div className="account__line-box">
            <div className="account__line-hr"></div>
          </div>
          {/* 2 - Настройки уведомлений и другое */}
          <div className="account__customizing">
            <div className="account__face-entry">
              <div className="text-box">
                <p>Вход по лицу</p>
              </div>
              <div className="line-box">
                <hr className="line-hr" />
              </div>
              <div className="switch-box">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="account__text-notifications-box">
              <p>Уведомления:</p>
            </div>
            <div className="account__customizing-down">
              {/* Строка - Получать уведомления */}
              <div className="account__customizing-item">
                <div className="text-box">
                  <p>Получать уведомления</p>
                </div>
                <div className="line-box">
                  <hr className="line-hr" />
                </div>
                <div className="switch-box">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {/* Строка - Способ получения */}
              <div className="account__method-of-receipt">
                <div className="text-box">
                  <p>Способ получения</p>
                </div>
                <div className="check-boxes-wrapper">
                  <div className="check-boxes-item">
                    {/* Чек-бокс */}
                    <label className="custom-checkbox">
                      <input type="checkbox" name="customCheckbox-email" className="custom-checkbox-input" />
                      <span className="custom-checkbox-icon"></span>
                    </label>
                    <p>E-mail</p>
                  </div>
                  <div className="check-boxes-item">
                    {/* Чек-бокс */}
                    <label className="custom-checkbox">
                      <input type="checkbox" name="customCheckbox-email" className="custom-checkbox-input" />
                      <span className="custom-checkbox-icon"></span>
                    </label>
                    <p>SMS</p>
                  </div>
                </div>
              </div>
              {/* Строка - Электронные чеки */}
              <div className="account__customizing-item">
                <div className="text-box">
                  <p>Электронные чеки</p>
                </div>
                <div className="line-box">
                  <hr className="line-hr" />
                </div>
                <div className="switch-box">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {/* Строка - Скидки и акции */}
              <div className="account__customizing-item">
                <div className="text-box">
                  <p>Скидки и акции</p>
                </div>
                <div className="line-box">
                  <hr className="line-hr" />
                </div>
                <div className="switch-box">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {/* Строка - Ваши новые фото */}
              <div className="account__customizing-item">
                <div className="text-box">
                  <p>Ваши новые фото</p>
                </div>
                <div className="line-box">
                  <hr className="line-hr" />
                </div>
                <div className="switch-box">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {/* Строка - Напоминание покупки */}
              <div className="account__customizing-item">
                <div className="text-box">
                  <p>Напоминание покупки</p>
                </div>
                <div className="line-box">
                  <hr className="line-hr" />
                </div>
                <div className="switch-box">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {/* Строка - Истечение срока хранения */}
              <div className="account__customizing-item">
                <div className="text-box">
                  <p>Истечение срока хранения</p>
                </div>
                <div className="line-box">
                  <hr className="line-hr" />
                </div>
                <div className="switch-box">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

          </div>
          {/* Разделительная линия */}
          <div className="account__line-box">
            <div className="account__line-hr"></div>
          </div>
          {/* 3 - Подключение способов оплаты */}
          <div className="account__payment-methods">
            <div className="account__payment-methods-left">
              <p>Способы оплаты:</p>
              <div className="account__payment-methods-box">
                {/* Строка - Карта и кнопка Удалить её */}
                <div className="account__payment-method-item">
                  {/* Слева Карта (реквизиты) */}
                  <div className="payment__cards-item">
                    {/* Left */}
                    <div className="payment__cards-item-left">
                      <div className="payment__cards-item-logo-box">
                        <img src={imageMastercard} alt="imageMastercard" />
                      </div>
                      <div className="payment__cards-item-name-bank">
                        <p>Т-Банк</p>
                      </div>
                    </div>
                    {/* Right */}
                    <div className="payment__cards-item-right">
                      <div className="payment__cards-item-card-number">
                        <p>**5297</p>
                      </div>
                      {/* Чек-бокс - Выбрать метод */}
                      <div className="image__checkbox-select-box hidden">
                        <label className="image__checkbox-select-method custom-checkbox">
                          <input type="radio" name="paymentCard" className="custom-checkbox-input" />
                          <span className="custom-checkbox-icon"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* Справа - Кнопка удалить */}
                  <div className="btn-garbage-box opacity30">
                    <img src={imageGarbage} alt="imageGarbage" />
                  </div>
                </div>
                {/* Строка - Карта и кнопка Удалить её */}
                <div className="account__payment-method-item">
                  {/* Слева Карта (реквизиты) */}
                  <div className="payment__cards-item">
                    {/* Left */}
                    <div className="payment__cards-item-left">
                      <div className="payment__cards-item-logo-box">
                        <img src={imageVisa} alt="imageVisa" />
                      </div>
                      <div className="payment__cards-item-name-bank">
                        <p>Сбербанк</p>
                      </div>
                    </div>
                    {/* Right */}
                    <div className="payment__cards-item-right">
                      <div className="payment__cards-item-card-number">
                        <p>**3260</p>
                      </div>
                      {/* Чек-бокс - Выбрать метод */}
                      <div className="image__checkbox-select-box hidden">
                        <label className="image__checkbox-select-method custom-checkbox">
                          <input type="radio" name="paymentCard" className="custom-checkbox-input" />
                          <span className="custom-checkbox-icon"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* Справа - Кнопка удалить */}
                  <div className="btn-garbage-box opacity30">
                    <img src={imageGarbage} alt="imageGarbage" />
                  </div>
                </div>
                {/* Строка - Карта и кнопка Удалить её */}
                <div className="account__payment-method-item">
                  {/* Слева Карта (реквизиты) */}
                  <div className="payment__cards-item">
                    {/* Left */}
                    <div className="payment__cards-item-left">
                      <div className="payment__cards-item-logo-box">
                        <img src={imageMir} alt="imageMir" />
                      </div>
                      <div className="payment__cards-item-name-bank">
                        <p>Банк Санкт-Петербург</p>
                      </div>
                    </div>
                    {/* Right */}
                    <div className="payment__cards-item-right">
                      <div className="payment__cards-item-card-number">
                        <p>**9823</p>
                      </div>
                      {/* Чек-бокс - Выбрать метод */}
                      <div className="image__checkbox-select-box hidden">
                        <label className="image__checkbox-select-method custom-checkbox">
                          <input type="radio" name="paymentCard" className="custom-checkbox-input" />
                          <span className="custom-checkbox-icon"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* Справа - Кнопка удалить */}
                  <div className="btn-garbage-box opacity30">
                    <img src={imageGarbage} alt="imageGarbage" />
                  </div>
                </div>
                {/* Строка - Добавить карту */}
                <div className="account__payment-method-add-card">
                  <div className="payment__add-card-box">
                    <p>+ добавить карту</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Предположительно для информирования об удаленни карты */}
            <div className="account__payment-methods-right hidden">
              <p>right-block</p>

            </div>
          </div>
        </div>
        {/* Нижний блок - Удалить аккаунт */}
        <div className="account__place-down">
          <div className="account__btn-delete-account-wrapper">
            <div className="image-box">
              <img src={imageCross} alt="imageCross" />
            </div>
            <div className="text-box">
              <p>Удалить аккаунт</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;